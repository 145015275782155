import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getTimeRatings, saveTimeRatings} from "../../api/api";
import {ErrorType, TimeRatingDto} from "../../api/dtos"
import TimeRatingsInput from "./TimeRatingsInput";

interface State {
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    ratings: TimeRatingDto[]
    loading: boolean
}

interface EditTimeRatingsPageProps {
    redirectTo: string
}

type Props = BasePageProps & EditTimeRatingsPageProps & RouteComponentProps<{ groupplayId: string }>

class EditTimeRatingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            ratings: [],
            loading: true
        };
        this.handleSaveTimeRatings = this.handleSaveTimeRatings.bind(this);
    }

    async componentDidMount() {
        if (this.props.session) {
            try {
                const ratings: TimeRatingDto[] = await getTimeRatings(this.props.session.sessionId);
                this.setState({ratings: ratings});
            } catch (error:any) {
                console.log('error type:' + error.type);
                switch (error.type) {
                    case ErrorType.INVALID_SESSION:
                        this.setState({redirectTo: this.props.redirectTo});
                        break;
                    default:
                        this.setState({errorMessage: "", fatalError: true});
                        break;
                }
            }
        } else {
            //no session, redirect away
            this.setState({redirectTo: this.props.redirectTo});
        }
        this.setState({loading: false});
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }

        return (<BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError} sessionRequired={true}>
                <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>
                    <TimeRatingsInput ratings={this.state.ratings}
                                      onSave={this.handleSaveTimeRatings} redirectTo={this.props.redirectTo}/>
                </div>
            </BasePage>
        );
    }

    async handleSaveTimeRatings(ratings: TimeRatingDto[]) {
        if (this.props.session) {
            try {
                this.setState({loading: true})
                await saveTimeRatings(ratings, this.props.session.sessionId);
                const freshRatings: TimeRatingDto[] = await getTimeRatings(this.props.session.sessionId);
                this.setState({ratings: freshRatings});
            } catch (error:any) {
                console.log('error caught:' + error);
                console.log('error type:' + error.type);
                switch (error.type) {
                    default:
                        this.setState({errorMessage: "", fatalError: true});
                        break;
                }
                return;
            }
            this.setState({loading: false});
            this.goBack();
        }
    }

    goBack() {
        this.setState({redirectTo: this.props.redirectTo});
    }

}

export default EditTimeRatingsPage;