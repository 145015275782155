import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getStandingsForRound} from "../../api/api";
import {RoundStandingsDto} from "../../api/dtos";
import FullPageMessage from "../../components/FullPageMessage";
import Standings from "../standings/Standings";
import {Link} from "react-router-dom";
import {mobile} from "../../App";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string, roundNr: string }>

interface State {
    loading: boolean
    fatalError: boolean
    standings?: RoundStandingsDto
}

export default class HistoryStandingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
        }
    }

    async componentDidMount() {
        this.setState({loading: true});
        const {groupplayId, roundNr} = this.props.match.params;
        const roundNrInt = parseInt(roundNr);
        if (isNaN(roundNrInt)) {
            this.setState({fatalError: true, loading: false});
            return;
        }
        try {
            const standingsDto = await getStandingsForRound(groupplayId, roundNrInt);
            this.setState({standings: standingsDto});
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case "NOT_FOUND":
                    //just ignore..
                    break;
                default:
                    this.setState({fatalError: true});
                    break;
            }
        }
        this.setState({loading: false})
    }

    render() {
        const {groupplayId, roundNr} = this.props.match.params;
        const {standings} = this.state;

        return (
            <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                      hideStandingsButton={true}>
                {!standings &&
                <FullPageMessage text={"Hittar inte omgång " + roundNr + ".."}/>}

                {standings && <>
                {mobile ? <>
                        <div style={{textAlign: "center", fontSize: "2rem"}}>Resultat för omgång {standings.roundNumber}
                        </div>
                        <div style={{textAlign: "center", fontSize: "2rem"}}>({standings.firstMatchStart.date} till {standings.openUntil.date})
                        </div>
                    </> :
                    <div style={{textAlign: "center", fontSize: "1.5rem"}}>Resultat för
                        omgång {standings.roundNumber} ({standings.firstMatchStart.date} till {standings.openUntil.date})
                    </div>}
                    <Link style={{textAlign: "center", fontSize:mobile?"2rem":"1rem"}} to={"/gruppspel/" + groupplayId + "/history"}>tillbaka</Link>
                    <Standings standings={standings} groupplayId={groupplayId}/>
                </>}
            </BasePage>
        );
    }
}