import {PlayerState} from "../api/dtos";

export default class StateToString {

    public static stateToString(state: PlayerState): string {
        switch (state) {
            case PlayerState.DONT_WANNA_JOIN:
                return "Nej tack";
            case PlayerState.WANNA_JOIN:
                return "Vill börja";
            case PlayerState.WANNA_PAUSE:
                return "Vill pausa";
            case PlayerState.WANNA_QUIT:
                return "Vill sluta";
            case PlayerState.PAUSE:
                return "Paus";
            case PlayerState.PLAYING:
                return "Spelar";
            case PlayerState.NO_ANSWER:
                return "Ej svarat";
            default:
                return "?";
        }
    }
}
