import * as React from "react"
import {PlayerDto, PlayerState} from "../../../api/dtos"
import {mobile} from "../../../App"
import StateToString from "../../../util/StateToString"
import Modal from "../../../components/Modal"
import {LinkButton} from "../../../components/LinkButton"

interface Props {
    player: PlayerDto
    onClose: () => void
    savePlayer: (player: PlayerDto) => void
}

interface State {
    comment: string
    playerState: PlayerState
}

export default class EditPlayerModal extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            comment: this.props.player.comment,
            playerState: this.props.player.state,
        };
    }

    render() {
        const player = this.props.player;
        const fontSize = mobile ? "2rem" : "1rem";
        const inPrevRound: boolean = player.ongoingRoundInfo !== undefined;
        const type = this.state.playerState;
        return (
            <Modal height={mobile ? "50rem" : "20rem"}>
                <div style={{fontSize: mobile ? "4rem" : "2rem", textAlign: "center"}}>
                    {player.account.firstName} {player.account.lastName}
                </div>
                <div style={{
                    position: "absolute", fontSize: fontSize,
                    top: mobile ? "6rem" : "3rem", left: "0.5rem"
                }}>E-mail:
                </div>
                <div style={{
                    position: "absolute",
                    top: mobile ? "8rem" : "4rem",
                    left: "0.5rem",
                    fontSize: mobile ? "3rem" : "1.5rem"
                }}>{player.account.email}</div>
                <div style={{
                    position: "absolute", fontSize: fontSize,
                    top: mobile ? "12rem" : "6rem", left: "0.5rem"
                }}>Mobil:
                </div>
                <div style={{
                    position: "absolute",
                    top: mobile ? "14rem" : "7rem",
                    left: "0.5rem",
                    fontSize: mobile ? "3rem" : "1.5rem"
                }}>{player.account.phone}</div>
                <div style={{
                    position: "absolute",
                    top: mobile ? "18rem" : "9rem",
                    left: "0.5rem",
                    fontSize: fontSize
                }}>Kommentar:
                </div>
                <textarea style={{
                    position: "absolute",
                    top: mobile ? "21rem" : "10.5rem",
                    left: "0.5rem",
                    background: "var(--buttonBg)",
                    width: mobile ? "38.5rem" : "19.2rem",
                    height: mobile ? "5rem" : "2.5rem",
                    resize: "none",
                    border: "none",
                    padding: "0.3rem",
                    overflow: "hidden",
                    fontSize: fontSize
                }}
                          rows={2} value={this.state.comment} onChange={this.commentChange}/>
                <div style={{
                    position: "absolute", fontSize: fontSize,
                    top: mobile ? "28rem" : "14rem", left: "0.5rem"
                }}>Status: {StateToString.stateToString(this.state.playerState)}
                </div>
                <div style={{position: "absolute", top: mobile ? "30rem" : "16rem"}}>
                    {inPrevRound && type !== PlayerState.WANNA_PAUSE &&
                    <button style={{position: "absolute", left: "0", width: mobile ? "19rem" : "6rem"}}
                            onClick={() => this.typeChange(PlayerState.WANNA_PAUSE)}>{StateToString.stateToString(PlayerState.WANNA_PAUSE)}</button>}
                    {inPrevRound && type !== PlayerState.WANNA_QUIT &&
                    <button style={{position: "absolute",left: mobile ? "20rem" : "6.5rem",width: mobile ? "17rem" : "6rem"}}
                            onClick={() => this.typeChange(PlayerState.WANNA_QUIT)}>{StateToString.stateToString(PlayerState.WANNA_QUIT)}</button>}
                    {!inPrevRound && type !== PlayerState.WANNA_JOIN &&
                    <button style={{position: "absolute", left: "0", width: mobile ? "18rem" : "6rem"}}
                            onClick={() => this.typeChange(PlayerState.WANNA_JOIN)}>{StateToString.stateToString(PlayerState.WANNA_JOIN)}</button>}
                    {inPrevRound && type !== PlayerState.PLAYING &&
                    <button style={{position: "absolute",top: mobile ? "7rem" : "0",left: mobile ? "0rem" : "13rem",width: mobile ? "17rem" : "6rem"}}
                            onClick={() => this.typeChange(PlayerState.PLAYING)}>{StateToString.stateToString(PlayerState.PLAYING)}</button>}
                    {!inPrevRound && type !== PlayerState.PAUSE &&
                    <button style={{position: "absolute",left: mobile ? "20rem" : "6.5rem",width: mobile ? "17rem" : "6rem"}}
                            onClick={() => this.typeChange(PlayerState.PAUSE)}>{StateToString.stateToString(PlayerState.PAUSE)}</button>}
                </div>
                <div style={{position: "absolute", right: 0, bottom: 0}}>
                    <LinkButton style={{fontSize: mobile ? "3rem" : "1rem", marginRight: "1rem", textAlign: "right"}}
                                onClick={this.props.onClose}/>
                    <button disabled={this.saveDisabled()} onClick={this.onSaveClick}> Spara</button>
                </div>
            </Modal>
        );
    }

    private commentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event.currentTarget.clientHeight === event.currentTarget.scrollHeight) {
            //only allow two rows (number of rows visible in the textarea
            this.setState({comment: event.currentTarget.value});
        }
    }

    private typeChange(state: PlayerState) {
        this.setState({playerState: state});
    }

    private saveDisabled = (): boolean => {
        return this.state.comment === this.props.player.comment &&
            this.state.playerState === this.props.player.state;
    }

    private onSaveClick = async () => {
        this.props.savePlayer(
            {...this.props.player, comment: this.state.comment, state: this.state.playerState});
    }
}