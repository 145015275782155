import * as React from "react"
import BasePage, {BasePageProps} from "../BasePage";
import {Redirect, RouteComponentProps} from "react-router";
import TextInput from "../../components/TextInput";
import FullPageMessage from "../../components/FullPageMessage";
import {resetPassword, resetPasswordInfo} from "../../api/api";
import FullPageSpinner from "../../components/FullPageSpinner";
import {ErrorType, ResetPasswordLinkInfoDto} from "../../api/dtos"
import {ErrorMessage} from "../../components/ErrorMessage";

interface State {
    errorMessage?: string
    fatalError: boolean
    password1: string
    password2: string
    passwordsInvalid: boolean
    redirectTo?: string
    showItsDone: boolean,
    rememberMe: boolean,
    loading: boolean,
    invalidLink: boolean,
    name: string,
    email: string
}

type Props = BasePageProps & RouteComponentProps<{ linkId: string, groupplayId: string }>

class ResetPasswordPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            password1: '',
            password2: '',
            fatalError: false,
            passwordsInvalid: false,
            showItsDone: false,
            rememberMe: false,
            loading: true,
            invalidLink: false,
            name: '',
            email: ''
        };
        this.handlePassword1Change = this.handlePassword1Change.bind(this);
        this.handlePassword2Change = this.handlePassword2Change.bind(this);
        this.okClick = this.okClick.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
    }

    async componentDidMount() {
        const {linkId} = this.props.match.params;
        try {
            let linkInfo: ResetPasswordLinkInfoDto = await resetPasswordInfo(linkId);
            this.setState({name: linkInfo.firstName + ' ' + linkInfo.lastName, email: linkInfo.email});
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case ErrorType.INVALID_LINK_ID:
                    this.setState({invalidLink: true});
                    break;
                default:
                    this.setState({fatalError: true});
                    break;
            }

        }
        this.setState({loading: false});
    }

    handlePassword1Change(currentValue: string) {
        this.setState({
            password1: currentValue,
            passwordsInvalid: this.state.password2 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }

    handlePassword2Change(currentValue: string) {
        this.setState({
            password2: currentValue,
            passwordsInvalid: this.state.password1 !== currentValue || (currentValue.length < 4 && currentValue.length > 0)
        });
    }


    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        if (this.state.loading) {
            return (<FullPageSpinner/>);
        }
        if (this.state.invalidLink) {
            return (
                <FullPageMessage text="Felaktig eller gammal länk.."/>
            );
        }
        return (<BasePage {...this.props} fatalError={this.state.fatalError}>
                {this.state.showItsDone ? this.renderItsDone() : this.renderForm()}
            </BasePage>
        );
    }

    renderForm() {
        return (
            <div style={{margin: "auto", display: "flex", flexDirection: "column"}}>

                <span style={{marginLeft: "0.5rem"}}>{this.state.name}</span>
                <TextInput onChange={this.handlePassword1Change} placeHolder={"Nytt lösenord"}
                           invalid={this.state.passwordsInvalid} password={true}/>
                <TextInput onChange={this.handlePassword2Change} placeHolder={"Nytt lösenord igen"}
                           invalid={this.state.passwordsInvalid} password={true}/>
                <ErrorMessage text={this.state.errorMessage}/>
                <button onClick={this.handleSaveClick}
                        style={{width: "12rem", margin: "0.5rem"}}
                        disabled={this.saveDisabled()}>Spara
                </button>
            </div>
        );
    }

    renderItsDone() {
        return (
            <FullPageMessage text="Ditt nya lösenord är sparat!"
                             button={"Okej"} onButtonClick={this.okClick}/>
        );
    }

    okClick() {
        console.log("okClick");
        this.setState({redirectTo: '/'});
    }

    saveDisabled(): boolean {
        return this.state.password1.length < 4 ||
            this.state.passwordsInvalid;
    }

    async handleSaveClick() {
        const {linkId} = this.props.match.params;
        try {
            const sessionDto = await resetPassword({linkId: linkId, newPassword: this.state.password1});
            this.props.onSessionChange(this.state.rememberMe, sessionDto);
            this.setState({showItsDone: true});
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case ErrorType.INVALID_LINK_ID:
                    this.setState({errorMessage: "E-mail verifieringen misslyckades."});
                    break;
                case ErrorType.EMAIL_ALREADY_VERIFIED:
                    this.setState({errorMessage: "E-mail adressen är redan verifierad!"});
                    break;
                default:
                    this.setState({errorMessage: error.type});
                    break;
            }

        }

    }
}

export default ResetPasswordPage;