import * as React from "react"
import {mobile} from "../App"

interface Props {
    text?: string
    width?: string
}

export default class BorderWithText extends React.Component<Props> {

    render() {
        const fontSize = mobile ? "2rem" : "1rem";
        const marginTop = mobile ? "-1.6rem" : "-1rem";
        return (
            <div style={{
                border: "1px solid", borderColor: "var(--pageText)", borderRadius: "0.1rem",
                padding: "0.4rem", maxWidth: (this.props.width ? this.props.width : "max-content"), margin: "1rem 0.5rem",
                fontSize: fontSize
            }}>
                {this.props.text &&
                <div style={{
                    marginTop: marginTop, marginLeft: "0.5rem", backgroundColor: "var(--pageBg)",
                    width: "max-content", paddingLeft: "0.5rem", paddingRight: "0.5rem"
                }}>{this.props.text}</div>}
                {this.props.children}
            </div>
        );
    }
};
