import * as React from "react"
import {BookingDto} from "../../../api/dtos"
import FullPageMessage from "../../../components/FullPageMessage"
import Checkbox from "../../../components/Checkbox"
import DateUtil from "../../../util/DateUtil"
import Table from "../../../components/Table"
import TableRow from "../../../components/TableRow"
import TableText from "../../../components/TableText"
import TableCheckbox from "../../../components/TableCheckbox"

interface Props {
    bookings: BookingDto[]
    bookingChanged: (booking: BookingDto) => any
    nrOfMatches: number
    nrOfExtraBookings: number
}

export default class BookingsTable extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.checkboxChanged = this.checkboxChanged.bind(this);
    }

    render() {
        const {bookings} = this.props;
        if (bookings.length === 0) {
            return (<FullPageMessage text="Hittar inga bokningar.."/>);
        }
        let usedCounter = 0;
        return (
            <Table>
                {bookings.map((b, i) => {
                    if (!b.dontUse) {
                        usedCounter++;
                    }
                    const lastRow: boolean = bookings.length === (i + 1);
                    const candidate: boolean = (usedCounter <= (this.props.nrOfExtraBookings + this.props.nrOfMatches));
                    const used: boolean = candidate && !b.dontUse;

                    return this.getBooking(b, lastRow, candidate, used, usedCounter);
                })}
            </Table>);
    }

    getBooking(b: BookingDto, lastRow: boolean, candidate: boolean, used: boolean, usedCounter: number) {
        return (
            <TableRow key={b.id} width="24rem" lastRow={lastRow}>
                <TableText left="0" mobLeft="0">{used ? usedCounter : " "}</TableText>
                <TableText left="3rem" mobLeft="5rem">{DateUtil.dayName(b.time.day)}</TableText>
                <TableText left="7rem" mobLeft="14rem">{b.time.date}</TableText>
                <TableText left="14rem" mobLeft="27rem">kl {b.time.hour}</TableText>
                <TableText left="18rem" mobLeft="35rem">{b.court}</TableText>
                <TableCheckbox right="0" mobRight="0">
                    {candidate && <Checkbox id={b.id} checked={used}
                                            onChange={(checked: boolean) => this.checkboxChanged(b, checked)}/>}
                </TableCheckbox>
            </TableRow>
        );
    }

    checkboxChanged(booking: BookingDto, checked: boolean) {
        this.props.bookingChanged({...booking, dontUse: !checked});
    };
}