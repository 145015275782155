import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import GroupplayList from "./GroupplayList";
import {mobile} from "../../App";

interface State {
    fatalError: boolean
}

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

class StartPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false
        };
        this.onFataError = this.onFataError.bind(this);
    }

    render() {
        return (
            <BasePage {...this.props} fatalError={this.state.fatalError}>
                <div style={{fontSize: "6rem", textAlign: "center"}}>Gruppspelet.se</div>
                <div style={{fontSize: "3rem", textAlign: "center"}}>Ett modernt och lättanvänt <br/>gruppspelsystem!
                    </div>
                {mobile ?
                    <div style={{fontSize: "2rem", textAlign: "center", marginTop: "6rem"}}>Gå till gruppspel:</div> :
                    <div style={{fontSize: "2rem", textAlign: "center", marginTop: "6rem"}}>Gå till gruppspel:</div>}
                <GroupplayList onFatalError={this.onFataError}/>
            </BasePage>
        );
    }

    onFataError() {
        this.setState({fatalError: true});
    }

}

export default StartPage;