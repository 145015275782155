import * as React from "react"
import {CSSProperties} from "react"
import {mobile} from "../App"

interface Props {
}

export default class DropDown extends React.Component<Props> {

    render() {
        const style: CSSProperties = mobile ?
            {
                position: "absolute", backgroundColor: "var(--buttonBg)", color: "var(--buttonText)",
                fontSize: "4rem", marginTop: "4.5rem", marginLeft: "0.3rem", zIndex: 1000
            }
            :
            {
                position: "absolute", backgroundColor: "var(--buttonBg)", color: "var(--buttonText)",
                marginTop: "1.5rem", marginLeft: "0.05rem", zIndex: 1000
            };

        return (
            <div style={style}>
                <ul style={{padding: 0, margin: 0, listStyle: "none"}}>
                    {this.props.children}
                </ul>
            </div>);
    }
}