import * as React from "react"
import {ChangeEvent} from "react"
import {RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../../BasePage"
import {getRuleText, saveRuleText} from "../../../api/api"
import {RuleParagraphDto} from "../../../api/dtos"
import {mobile} from "../../../App"
import Panel from "../../../components/Panel"
import TableButton, {ButtonType} from "../../../components/TableButton"

interface State {
    fatalError: boolean
    loading: boolean
    saveButtonDisabled: boolean
    errorMessage?: string
    paragraphs: ClientRuleParagraph[]
}

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

export default class RulesTextConfigPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            saveButtonDisabled: true,
            paragraphs: []
        };
    }

    async componentDidMount() {
        try {
            const {groupplayId} = this.props.match.params;
            const paragraphDtos: RuleParagraphDto[] = await getRuleText(groupplayId);

            const refParagraphs: ClientRuleParagraph[] = paragraphDtos
                .map((p) => {
                    return {number: p.number, heading: p.heading, text: p.text, rows: undefined, ref: React.createRef()}
                });

            this.setState({paragraphs: refParagraphs, loading: false});


            //set initial size of textareas
            const newParagraphs = refParagraphs.map(p => {
                if (p.rows === undefined) {
                    return {...p, text: p.text, rows: this.getRows(p.ref.current)}
                }
                return p;
            });
            this.setState({paragraphs: newParagraphs});

        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                default:
                    this.setState({fatalError: true, loading: false});
                    break;
            }
        }
    }

    render() {
        const fontSize = mobile ? "1.5rem" : "1rem";
        const {loading, fatalError, paragraphs} = this.state;
        return (
            <BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <span style={{textAlign: "center", fontSize: mobile ? "4rem" : "2rem"}}>Redigera regler</span>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "0 1.5rem"
                }}>
                    <button style={{width: mobile ? "23rem" : "9rem"}} onClick={this.addParagraph}>Ny Paragraf</button>
                    <button style={{width: mobile ? "12rem" : "9rem"}} onClick={this.save}>Spara</button>
                </div>
                {paragraphs.map((p, i) =>
                    <Panel key={p.number}>
                        {mobile ?
                            <>
                                <div>§ {p.number} <input style={{width: "36rem", fontSize: fontSize}} type="text"
                                                         value={p.heading}
                                                         onChange={e => this.headingChanged(p.number, e)}/>
                                </div>
                                <div style={{position: "relative", height: "6rem"}}>
                                    <TableButton type={ButtonType.UP_ARROW} onClick={() => this.moveUp(p.number)}
                                                 disabled={this.upDisabled(p.number)} top="-1rem" mobRight="1rem"/>
                                    <TableButton type={ButtonType.DOWN_ARROW} onClick={() => this.moveDown(p.number)}
                                                 disabled={this.downDisabled(p.number)} top="-1rem" mobRight="7rem"/>
                                    <TableButton type={ButtonType.REMOVE} onClick={() => this.deleteParagraph(p.number)}
                                                 top="-1rem" mobRight="14rem"/>
                                </div>
                            </> :
                            <>
                                § {p.number} <input style={{width: "30rem", fontSize: fontSize}} type="text"
                                                    value={p.heading}
                                                    onChange={e => this.headingChanged(p.number, e)}/>
                                <TableButton type={ButtonType.UP_ARROW} onClick={() => this.moveUp(p.number)}
                                             disabled={this.upDisabled(p.number)} top="0.6rem" right="1rem"/>
                                <TableButton type={ButtonType.DOWN_ARROW} onClick={() => this.moveDown(p.number)}
                                             disabled={this.downDisabled(p.number)} top="0.6rem" right="3rem"/>
                                <TableButton type={ButtonType.REMOVE} onClick={() => this.deleteParagraph(p.number)}
                                             top="0.6rem" right="5rem"/>
                            </>}
                        <textarea ref={p.ref} style={{
                            background: "var(--buttonBg)",
                            width: "45.5rem",
                            resize: "none",
                            border: "none",
                            padding: "0.3rem",
                            overflow: "hidden",
                            lineHeight: mobile ? "1.75rem" : "1.15rem",
                            fontSize: fontSize
                        }}
                                  rows={p.rows ? p.rows : 1} value={p.text}
                                  onChange={e => this.textChanged(p.number, e)}/>
                    </Panel>
                )}
            </BasePage>
        );
    }

    private addParagraph = () => {
        const {paragraphs} = this.state;
        const newNumber = paragraphs.length + 1;
        const newParagraphs: ClientRuleParagraph[] = [...paragraphs, {
            number: newNumber,
            heading: "blipp",
            text: "blapp",
            rows: undefined,
            ref: React.createRef()
        }];
        this.setState({paragraphs: newParagraphs});
    };

    private save = async () => {
        const {session} = this.props;
        if (session) {
            this.setState({loading: true});
            const {groupplayId} = this.props.match.params;
            const {paragraphs} = this.state;
            const dtos: RuleParagraphDto[] = paragraphs.map(p => {
                return {number: p.number, heading: p.heading, text: p.text};
            });
            await saveRuleText(groupplayId, dtos, session.sessionId);
            this.setState({loading: false});
        }
    };

    private downDisabled = (paragraphNumber: number): boolean => {
        const {paragraphs} = this.state;
        return paragraphNumber === paragraphs.length;
    };

    private upDisabled = (paragraphNumber: number): boolean => {
        return paragraphNumber === 1;
    };

    private moveUp = (paragraphNumber: number) => {
        const {paragraphs} = this.state;
        const newParagraphs = paragraphs
            .map(p => {
                if (p.number === paragraphNumber) {
                    return {...p, number: p.number - 1}
                }
                if (p.number === (paragraphNumber - 1)) {
                    return {...p, number: p.number + 1}
                }
                return p
            })
            .sort((p1, p2) => p1.number - p2.number);
        this.setState({paragraphs: newParagraphs});
    };

    private moveDown = (paragraphNumber: number) => {
        const {paragraphs} = this.state;
        const newParagraphs = paragraphs
            .map(p => {
                if (p.number === paragraphNumber) {
                    return {...p, number: p.number + 1}
                }
                if (p.number === (paragraphNumber + 1)) {
                    return {...p, number: p.number - 1}
                }
                return p
            })
            .sort((p1, p2) => p1.number - p2.number);
        this.setState({paragraphs: newParagraphs});
    };

    private deleteParagraph = (paragraphNumber: number) => {
        const {paragraphs} = this.state;
        const newParagraphs = paragraphs
            .filter(p => p.number !== paragraphNumber)
            .map(p => {
                if (p.number > paragraphNumber) {
                    return {...p, number: p.number - 1}
                }
                return p
            });
        this.setState({paragraphs: newParagraphs});
    };

    private headingChanged = (paragraphNumber: number, e: ChangeEvent<HTMLInputElement>) => {
        const {paragraphs} = this.state;
        const newParagraphs = paragraphs.map(p => {
            if (p.number === paragraphNumber) {
                return {...p, heading: e.target.value}
            }
            return p;
        });
        this.setState({paragraphs: newParagraphs});
    };

    private textChanged = (paragraphNumber: number, e: ChangeEvent<HTMLTextAreaElement>) => {
        const {paragraphs} = this.state;
        const newParagraphs = paragraphs.map(p => {
            if (p.number === paragraphNumber) {
                return {...p, text: e.target.value, rows: this.getRows(e.target)}
            }
            return p;
        });
        this.setState({paragraphs: newParagraphs});
    };

    private getRows(textarea: HTMLTextAreaElement) {
        textarea.rows = 1; // reset number of rows in textarea
        const textareaLineHeight = ~~(textarea.clientHeight * (mobile ? 0.75 : 0.6666)); //secret number after imperial testing..
        const currentRows = ~~(textarea.scrollHeight / textareaLineHeight);
        // console.log("scrollHeight:" + textarea.scrollHeight + ", clientHeight:" + textarea.clientHeight);
        textarea.rows = currentRows;
        return currentRows;
    }
}

interface ClientRuleParagraph {
    ref: any
    rows?: number  //used in client to size the text area..
    number: number
    heading: string
    text: string
}