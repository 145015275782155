import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getScheduleForRound} from "../../api/api";
import {ScheduleDto} from "../../api/dtos";
import FullPageMessage from "../../components/FullPageMessage";
import {Link} from "react-router-dom";
import {mobile} from "../../App";
import Schedule from "../schedule/Schedule";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string, roundNr: string }>

interface State {
    loading: boolean
    fatalError: boolean
    schedule?: ScheduleDto
}

export default class HistorySchedulePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
        }
    }

    async componentDidMount() {
        this.setState({loading: true});
        const {groupplayId, roundNr} = this.props.match.params;
        const {session} = this.props;
        const roundNrInt = parseInt(roundNr);
        if (isNaN(roundNrInt)) {
            this.setState({fatalError: true, loading: false});
            return;
        }
        try {
            const scheduleDto = await getScheduleForRound(groupplayId, roundNrInt, session?session.sessionId:undefined);
            this.setState({schedule: scheduleDto});
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case "NOT_FOUND":
                    //just ignore..
                    break;
                default:
                    this.setState({fatalError: true});
                    break;
            }
        }
        this.setState({loading: false})
    }

    render() {
        const {groupplayId, roundNr} = this.props.match.params;
        const {schedule} = this.state;

        return (
            <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                      hideStandingsButton={true}>
                {!schedule &&
                <FullPageMessage text={"Hittar inte omgång " + roundNr + ".."}/>}

                {schedule && <>
                    <div style={{textAlign: "center", fontSize: mobile?"2.5rem":"1.5rem"}}>Spelschema för omgång {schedule.roundNumber}</div>
                    <Link style={{textAlign: "center", fontSize:mobile?"2rem":"1rem"}} to={"/gruppspel/" + groupplayId + "/history"}>tillbaka</Link>
                    <Schedule matches={schedule.matches} session={this.props.session} groupplayId={groupplayId}/>
                </>}
            </BasePage>
        );
    }
}