import * as React from "react"
import Spinner from "./Spinner"
import {mobile} from "../App"

interface Props {
    text?: string
}

export default class FullPageSpinner extends React.Component<Props> {
    render() {
        const text = this.props.text;
        const fontSize = mobile ? "3rem" : "1.5rem";
        return (
            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <Spinner/>
                {text && <div style={{margin: "auto", fontSize: fontSize, textAlign: "center"}}>{text}</div>}
            </div>
        );
    }
}