import * as React from "react"
import {CSSProperties} from "react"
import {mobile} from "../App"
import {Link} from "react-router-dom"

export enum ButtonType {
    DOWN_ARROW,
    UP_ARROW,
    REMOVE,
    HAMBURGER,
    ADD,
    STANDINGS,
    BILLING,
    SCHEDULE,
    COPY_MAIL_ADDRESSES,
    BOOK

}

interface Props {
    type: ButtonType
    onClick?: () => any
    left?: string
    mobLeft?: string
    right?: string
    mobRight?: string
    disabled?: boolean
    link?: string
    top?: string
    mobDowner?: boolean //only works if fourMobRows=true on row
}

export default class TableButton extends React.Component<Props> {
    readonly baseDesktopCss: CSSProperties = {
        position: "absolute",
        padding: 0,
        height: "1.4rem",
        width: "1.4rem",
    };
    readonly baseMobileCss: CSSProperties = {
        position: "absolute",
        height: "4.5rem",
        width: "4.5rem",
        padding: 0
    };
    render() {
        if (this.props.link) {
            return (
                <Link to={this.props.link}>
                    {this.renderButton()}
                </Link>
            );
        } else {
            return this.renderButton();
        }
    }

    renderButton() {
        const topCss: CSSProperties = this.props.top ? {top: this.props.top} :
            (mobile ? (this.props.mobDowner ? {top: "4.8rem"} : {top: "-0.3rem"})
            : {top: 0});
        const leftCss: CSSProperties = mobile ?
            this.props.mobLeft ? {left: this.props.mobLeft} : {}
            :
            this.props.left ? {left: this.props.left} : {};
        const rightCss: CSSProperties = mobile ?
            this.props.mobRight ? {right: this.props.mobRight} : {}
            :
            this.props.right ? {right: this.props.right} : {};

        if (this.props.type === ButtonType.DOWN_ARROW) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss} :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss};
            return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>&darr;</button>);
        }
        if (this.props.type === ButtonType.UP_ARROW) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss} :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss};

            return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>&uarr;</button>);
        }
        if (this.props.type === ButtonType.REMOVE) {
            if (mobile) {
                const css = {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss};
                return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                    <svg style={{position: "absolute", top: "0.6rem", left: "0.6rem"}} width="0.8em" height="0.8em"
                         viewBox="0 0 612 612">
                        <path d="M510.812,85.933c-29.254-14.929-58.367-16.325-59.592-16.375c-0.246-0.012-0.492-0.017-0.737-0.017H404.18
				c0.003-0.139,0.022-0.273,0.022-0.415c0-26.812-12.761-48.09-35.931-59.913c-16.138-8.234-31.876-9.122-33.618-9.194
				C334.409,0.006,334.163,0,333.917,0h-55.832c-0.246,0-0.492,0.006-0.737,0.017c-1.741,0.074-17.48,0.96-33.616,9.194
				C220.56,21.035,207.8,42.313,207.8,69.124c0,0.142,0.017,0.276,0.022,0.415h-46.303c-0.246,0-0.492,0.006-0.737,0.017
				c-1.226,0.051-30.337,1.446-59.593,16.375c-28.241,14.41-61.905,44.075-61.905,103.548c0,9.581,7.767,17.35,17.35,17.35h15.245
				l67.087,390.755c1.43,8.328,8.65,14.416,17.099,14.416h299.873c8.449,0,15.67-6.088,17.099-14.416l67.087-390.755h15.245
				c9.581,0,17.35-7.768,17.35-17.35C572.718,130.006,539.053,100.341,510.812,85.933z M75.398,172.13
				c4.22-24.493,17.846-42.891,40.665-54.828c21.272-11.123,43.329-12.888,45.936-13.063h288.005
				c2.585,0.172,24.08,1.906,45.034,12.6c23.361,11.922,37.29,30.475,41.562,55.29L75.398,172.13L75.398,172.13z M242.5,69.125
				c0-13.566,5.156-22.656,16.226-28.599c8.889-4.773,18.372-5.701,19.886-5.825h54.742c1.736,0.142,11.12,1.102,19.92,5.825
				c11.07,5.944,16.228,15.033,16.228,28.599c0,0.142,0.017,0.276,0.022,0.415H242.48C242.482,69.401,242.5,69.265,242.5,69.125z
				 M441.312,577.301H170.688l-63.605-370.472h397.834L441.312,577.301z"/>
                        <path d="M306,519.57c9.581,0,17.35-7.768,17.35-17.35V257.909c0-9.581-7.768-17.35-17.35-17.35c-9.583,0-17.35,7.768-17.35,17.35
				V502.22C288.65,511.802,296.419,519.57,306,519.57z"/>
                        <path d="M203.782,503.754c0.801,9.022,8.373,15.816,17.261,15.816c0.513,0,1.032-0.023,1.553-0.068
				c9.545-0.847,16.596-9.273,15.749-18.816l-21.687-244.311c-0.847-9.545-9.265-16.609-18.817-15.749
				c-9.545,0.847-16.595,9.27-15.748,18.816L203.782,503.754z"/>
                        <path d="M389.404,519.502c0.52,0.045,1.04,0.068,1.553,0.068c8.889,0,16.462-6.794,17.263-15.816l21.687-244.313
				c0.847-9.545-6.202-17.968-15.748-18.816c-9.544-0.856-17.968,6.204-18.817,15.749l-21.687,244.311
				C372.808,510.229,379.859,518.655,389.404,519.502z"/>
                    </svg>
                </button>);
            } else {
                const css = {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss, width: "4.5rem"};
                return (
                    <button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>Ta bort</button>);
            }
        }
        if (this.props.type === ButtonType.HAMBURGER) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss} :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss};

            const svgStyle: CSSProperties = mobile ?
                {position: "absolute", top: "0.6rem", left: "0.6rem"}
                :
                {position: "absolute", top: "0.35rem", left: "0.3rem"};

            return (
                <button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                    <svg style={svgStyle} width="0.8em" height="0.8em"
                         viewBox="0 0 24.75 24.75">
                        <path
                            d="M0,3.875c0-1.104,0.896-2,2-2h20.75c1.104,0,2,0.896,2,2s-0.896,2-2,2H2C0.896,5.875,0,4.979,0,3.875z M22.75,10.375H2 c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h20.75c1.104,0,2-0.896,2-2C24.75,11.271,23.855,10.375,22.75,10.375z M22.75,18.875H2 c-1.104,0-2,0.896-2,2s0.896,2,2,2h20.75c1.104,0,2-0.896,2-2S23.855,18.875,22.75,18.875z"/>
                    </svg>
                </button>);
        }
        if (this.props.type === ButtonType.ADD) {
            if (mobile) {
                const css = {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss};
                return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                    <svg x="0px" y="0px" width="0.8em" height="0.8em" viewBox="0 0 420.238 420.238">
                        <path d="M402.05,7.498l-95.175,0.004c-0.073-0.004-0.146-0.004-0.22-0.004H116.767c-10.028,0-18.188,8.158-18.188,18.188V78.25
		H17.543C7.855,78.25,0,86.105,0,95.793v299.404c0,9.688,7.854,17.543,17.543,17.543h299.402c9.688,0,17.543-7.855,17.543-17.543
		V204.015h67.562c10.029,0,18.188-8.16,18.188-18.188V25.685C420.238,15.656,412.079,7.498,402.05,7.498z M229.896,43.871h58.57
		v123.77h-58.57V43.871z M324.182,43.871h58.57v123.77h-58.57V43.871z M134.954,43.871h58.568v123.77h-58.568V43.871L134.954,43.871
		z M35.086,377.654V113.336H98.58v72.492c0,10.027,8.159,18.188,18.188,18.188h73.979v20.191l-45.196,33.266l-15.817-21.487
		c-1.589-2.158-4.207-3.306-6.87-3.009s-4.966,1.992-6.04,4.445l-38.639,88.268c-0.423,0.966-0.63,1.988-0.63,3.009
		c0,1.573,0.495,3.137,1.46,4.446c1.588,2.156,4.207,3.304,6.87,3.009l95.764-10.66c2.663-0.297,4.966-1.99,6.04-4.445
		c1.073-2.456,0.758-5.297-0.83-7.452l-15.816-21.49l59.651-43.908c1.92-1.414,3.054-3.656,3.054-6.041v-38.139h65.655v173.639
		H35.086V377.654z"/>
                    </svg>
                </button>);
            } else {
                const css = {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss, width: "4.5rem"};
                return (
                    <button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>Lägg till</button>);
            }
        }
        if (this.props.type === ButtonType.COPY_MAIL_ADDRESSES) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss, width: "30rem"}
                :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss, width: "8rem"};
            return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                Kopiera mailadr.
            </button>);
        }
        if (this.props.type === ButtonType.STANDINGS) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss, width: "6rem"}
                :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss, width: "2rem"};
            return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                <svg key="svg" style={{verticalAlign: "middle", marginTop: mobile ? "-1rem" : "-0.15rem"}}
                     xmlns="http://www.w3.org/2000/svg"
                     width={mobile ? "1.3em" : "1.7em"} height={mobile ? "1.3em" : "1.7em"}
                     viewBox="0 0 476.473 476.474">
                    <path d="M463.468,227.853c-40.177-1.468-80.359-1.686-120.557-1.717c-0.492-43.122,0.635-70.609,0.122-113.731
			c3.448-7.695-0.284-18.88-11.294-18.88c-53.537,0-122.828,3.951-176.335,1.915c-2.323-0.635-4.857-0.666-7.289-0.094
			c-8.311,0.541-11.878,8.092-10.672,14.944c-1.828,25.624-1.823,51.295-2.214,76.967c-36.467,0.614-83.337,0.729-119.81,0.733
			c-5.512,0-9.179,2.829-11.065,6.604c-2.194,2.084-3.661,5.06-3.671,8.996c-0.099,51.455-1.473,108.105-0.025,159.555
			c-1.686,7.374,2.343,16.301,12.157,16.372c149.75,1.056,300.085,5.981,449.79,1.731c7.881-0.224,12.009-6.114,12.472-12.232
			c0.857-1.682,1.396-3.646,1.396-5.967V243.452c0-1.417-0.239-2.666-0.584-3.845C475.213,233.702,471.095,228.134,463.468,227.853z
			 M450.468,355.508c-141.143,3.544-282.803-0.65-423.988-1.858c-0.972-44.823-0.094-94.852,0.13-139.686
			c36.427-0.015,83.246-0.109,119.665-0.843c1.368-0.025,2.579-0.282,3.722-0.619c5.68-0.874,10.832-4.997,11.009-12.38
			c0.625-26.157,0.452-52.326,1.795-78.45c46.169,1.295,108.059-1.482,154.264-2.034c0.34,44.453-0.898,73.278-0.061,117.746
			c0.025,1.32,0.259,2.498,0.573,3.615c0.736,5.81,4.854,11.116,12.426,11.116c40.162,0,80.313,0.146,120.465,1.36V355.508z"/>
                    <path d="M243.48,134.334c-0.825,0.165-1.633,0.457-2.344,0.987c-7.81,5.819-15.861,11.314-23.244,17.685
			c-7.627,6.583,3.453,17.564,11.029,11.029c3.187-2.75,6.52-5.299,9.899-7.795c-0.229,18.382-0.63,36.762-0.681,55.145
			c-0.025,10.062,15.577,10.057,15.603,0c0.061-23.404,0.802-46.794,0.868-70.198C254.625,134.672,248.101,132.404,243.48,134.334z"
                    />
                    <path d="M112.476,285.919c-4.804,0-9.6,0.051-14.394,0.157c8.163-8.282,14.254-18.448,15.138-29.854
			c1.991-25.755-32.042-21.235-47.017-16.818c-9.625,2.834-5.527,17.891,4.148,15.041c5.33-1.574,29.597-9.343,27.025,3.413
			c-2.709,13.426-16.547,23.998-27.835,29.995c-2.471,1.311-3.61,3.296-3.829,5.383c-1.678,4.783,0.584,10.761,6.896,10.019
			c13.253-1.548,26.535-1.731,39.867-1.731C122.538,301.519,122.538,285.919,112.476,285.919z"/>
                    <path d="M373.319,288.661c5.397-1.138,10.785-2.097,16.274-2.574c2.691-0.239,5.81-0.29,8.293-0.076
			c-1.849,2.473-4.276,4.672-6.617,6.692c-1.533,0.178-3.017,0.873-4.296,2.234c-2.706,2.904-3.188,8.663,0,11.477
			c4.611,4.082,9.217,8.43,12.294,13.989c0.264,0.493,0.503,0.99,0.736,1.498c0.041,0.122,0.066,0.214,0.173,0.519
			c0.046,0.162,0.081,0.335,0.122,0.502c-0.082,0.021-0.117,0.031-0.152,0.041c-0.285,0.021-0.569,0.051-0.858,0.056
			c-2.311,0.112-3.428-0.203-5.946-0.889c-6.586-1.787-12.578-5.57-18.215-9.526c-8.19-5.753-15.768,8.303-7.638,14.016
			c11.004,7.733,31.316,19.565,43.646,8.201c10.456-9.644,1.818-23.704-6.683-33.139c8.079-8.161,16.188-20.125,4.769-28.854
			c-4.397-3.356-11.167-3.301-16.296-3.164c-7.916,0.214-15.873,1.722-23.627,3.357
			C359.725,275.032,363.788,290.672,373.319,288.661z"/>
                </svg>
            </button>)
        }
        if (this.props.type === ButtonType.SCHEDULE) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss}
                :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss};
            return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                <svg key="svg" style={{verticalAlign: "middle", marginTop: mobile?"-1rem":""}} xmlns="http://www.w3.org/2000/svg"
                     width={mobile?"0.95em":"1.2em"} height={mobile?"0.95em":"1.2em"} viewBox="0 0 29.237 29.237">
                    <path d="M7.685,24.819H8.28v-2.131h3.688v2.131h0.596v-2.131h3.862v2.131h0.597v-2.131h4.109v2.131h0.595
			v-2.131h3.417v-0.594h-3.417v-3.861h3.417v-0.596h-3.417v-3.519h3.417v-0.594h-3.417v-2.377h-0.595v2.377h-4.109v-2.377h-0.597
			v2.377h-3.862v-2.377h-0.596v2.377H8.279v-2.377H7.685v2.377H3.747v0.594h3.938v3.519H3.747v0.596h3.938v3.861H3.747v0.594h3.938
			V24.819z M12.563,22.094v-3.861h3.862v3.861H12.563z M21.132,22.094h-4.109v-3.861h4.109V22.094z M21.132,14.118v3.519h-4.109
			v-3.519C17.023,14.119,21.132,14.119,21.132,14.118z M16.426,14.118v3.519h-3.862v-3.519
			C12.564,14.119,16.426,14.119,16.426,14.118z M8.279,14.118h3.688v3.519H8.279V14.118z M8.279,18.233h3.688v3.861H8.279V18.233z"
                    />
                    <path d="M29.207,2.504l-4.129,0.004L24.475,2.51v2.448c0,0.653-0.534,1.187-1.188,1.187h-1.388
			c-0.656,0-1.188-0.533-1.188-1.187V2.514l-1.583,0.002v2.442c0,0.653-0.535,1.187-1.191,1.187h-1.388
			c-0.655,0-1.188-0.533-1.188-1.187V2.517l-1.682,0.004v2.438c0,0.653-0.534,1.187-1.189,1.187h-1.389
			c-0.653,0-1.188-0.533-1.188-1.187V2.525H8.181v2.434c0,0.653-0.533,1.187-1.188,1.187H5.605c-0.656,0-1.189-0.533-1.189-1.187
			V2.53L0,2.534v26.153h2.09h25.06l2.087-0.006L29.207,2.504z M27.15,26.606H2.09V9.897h25.06V26.606z"/>
                    <path d="M5.605,5.303h1.388c0.163,0,0.296-0.133,0.296-0.297v-4.16c0-0.165-0.133-0.297-0.296-0.297H5.605
			c-0.165,0-0.298,0.132-0.298,0.297v4.16C5.307,5.17,5.44,5.303,5.605,5.303z"/>
                    <path d="M11.101,5.303h1.389c0.164,0,0.297-0.133,0.297-0.297v-4.16c-0.001-0.165-0.134-0.297-0.298-0.297
			H11.1c-0.163,0-0.296,0.132-0.296,0.297v4.16C10.805,5.17,10.938,5.303,11.101,5.303z"/>
                    <path d="M16.549,5.303h1.388c0.166,0,0.299-0.133,0.299-0.297v-4.16c-0.001-0.165-0.133-0.297-0.299-0.297
			h-1.388c-0.164,0-0.297,0.132-0.297,0.297v4.16C16.252,5.17,16.385,5.303,16.549,5.303z"/>
                    <path d="M21.899,5.303h1.388c0.164,0,0.296-0.133,0.296-0.297v-4.16c0-0.165-0.132-0.297-0.296-0.297
			h-1.388c-0.164,0-0.297,0.132-0.297,0.297v4.16C21.603,5.17,21.735,5.303,21.899,5.303z"/>
                </svg>
            </button>);

        }
        if (this.props.type === ButtonType.BOOK) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss, width: "10rem"}
                :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss, width: "3rem"};
            return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                Boka
            </button>);
        }
        if (this.props.type === ButtonType.BILLING) {
            const css = mobile ?
                {...this.baseMobileCss, ...leftCss, ...rightCss, ...topCss, width: "3rem"}
                :
                {...this.baseDesktopCss, ...leftCss, ...rightCss, ...topCss, width: "1.5rem"};
            return (<button style={css} disabled={this.props.disabled} onClick={this.props.onClick}>
                $
            </button>);
        }
    }
}
