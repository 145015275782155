import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../BasePage"
import {getCantPlayDates, updateCantPlayDates} from "../../api/api"
import MonthDaySelector from "./MonthDaySelector"
import {mobile} from "../../App"
import {LinkButton} from "../../components/LinkButton"
import {ErrorType} from "../../api/dtos"

interface State {
    errorMessage?: string
    redirectTo?: string
    fatalError: boolean
    loading: boolean
    originalCantPlayDates: string[]
    cantPlayDates: string[]
}

interface EditTimeRatingsPageProps {
    redirectTo: string
}

type Props = BasePageProps & EditTimeRatingsPageProps & RouteComponentProps<{ groupplayId: string }>

export default class EditCantPlayDatesPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            cantPlayDates: [],
            originalCantPlayDates: []
        };
        this.saveClicked = this.saveClicked.bind(this);
        this.dayDeselected = this.dayDeselected.bind(this);
        this.daySelected = this.daySelected.bind(this);
        this.goBack = this.goBack.bind(this);
        this.saveDisabled = this.saveDisabled.bind(this);
    }

    async componentDidMount() {
        if (this.props.session) {
            try {
                const cantPlayDates: string[] = await getCantPlayDates(this.props.session.sessionId);
                this.setState({
                    cantPlayDates: cantPlayDates,
                    originalCantPlayDates: Object.assign([], cantPlayDates),
                    loading: false
                });
            } catch (error:any) {
                console.log('error caught:' + error);
                console.log('error type:' + error.type);
                switch (error.type) {
                    case ErrorType.INVALID_SESSION:
                        this.setState({redirectTo: this.props.redirectTo, loading: false});
                        break;
                    default:
                        this.setState({errorMessage: "", fatalError: true, loading: false});
                        break;
                }
            }
        } else {
            //no session, redirect away
            this.setState({redirectTo: this.props.redirectTo, loading: false});
        }
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        const currentMonth = new Date().getMonth();
        console.log("currentMonth=" + currentMonth);

        return (<BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError} sessionRequired={true}>
                {mobile ? this.mobilePage() : this.desktopPage()}
            </BasePage>
        );
    }

    desktopPage() {
        return <>
            <div style={{
                marginBottom: "2rem",
                margin: "auto",
                width: "40rem",
                textAlign: "center"
            }}>Klicka för dom dagar du inte kan spela på:
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
                {this.monthDaySelectors()}
            </div>
            <div style={{textAlign: "center", marginTop: "2rem"}}>
                <LinkButton style={{textAlign: "right", marginRight: "3rem"}} onClick={this.goBack}/>
                <button style={{width: "10rem"}} disabled={this.saveDisabled()}
                        onClick={this.saveClicked}>spara
                </button>
            </div>
        </>

    }

    mobilePage() {
        return <>
            <div style={{
                marginBottom: "2rem",
                margin: "auto",
                fontSize: "3rem",
                width: "80vw",
                textAlign: "center"
            }}>Klicka för dom dagar du inte kan spela på:
            </div>
            <div style={{display: "flex", flexDirection: "column", margin: "auto"}}>
                <div style={{marginBottom: "10rem"}}>
                    {this.monthDaySelectors()}
                </div>
                <div style={{
                    position: "fixed", right: 0, bottom: 0, width: "100%",
                    display: "flex", justifyContent: "flex-end", alignItems: "center", backgroundColor: "var(--pageBg)"
                }}>
                    <LinkButton style={{textAlign: "right", fontSize: "3rem", marginRight: "3rem"}} onClick={this.goBack}/>
                    <button style={{width: "20rem", margin: "1rem"}} disabled={this.saveDisabled()}
                            onClick={this.saveClicked}>spara
                    </button>
                </div>
            </div>
        </>
    }

    monthDaySelectors() {
        const cantPlayDayes = this.state.cantPlayDates;
        const months : React.JSX.Element[] = [];
        const date = new Date();
        date.setDate(1);
        let monthsCounter = 0;
        while (monthsCounter < 6) {
            months.push(
                <MonthDaySelector key={"month" + monthsCounter} month={date.getMonth()} year={date.getFullYear()}
                                  selectedDays={cantPlayDayes}
                                  onSelected={this.daySelected} onDeselected={this.dayDeselected}/>);
            monthsCounter++;
            date.setMonth(date.getMonth() + 1);
        }
        return mobile ?
            months :
            <div style={{display: "flex", flexDirection: "column", marginTop: "1rem"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly"
                }}>{months.splice(0, 3)}</div>
                <div style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly"
                }}>{months}</div>
            </div>;
    }

    daySelected(date: string) {
        console.log("daySelected(" + date + ")");
        const cantPlayDates = this.state.cantPlayDates;
        cantPlayDates.push(date);
        this.setState({cantPlayDates: cantPlayDates});
    }

    dayDeselected(date: string) {
        console.log("dayDeselected(" + date + ")");
        const cantPlayDates = this.state.cantPlayDates;
        const index = cantPlayDates.indexOf(date);
        if (index > -1) {
            cantPlayDates.splice(index, 1);
        }
        this.setState({cantPlayDates: cantPlayDates});
    }

    saveDisabled(): boolean {
        const {originalCantPlayDates, cantPlayDates} = this.state;
        if (originalCantPlayDates.length !== cantPlayDates.length) {
            return false;
        }
        originalCantPlayDates.sort();
        cantPlayDates.sort();
        for (let i = 0; i < originalCantPlayDates.length; i++) {
            if (originalCantPlayDates[i] !== cantPlayDates[i]) {
                return false;
            }
        }
        return true;
    }

    async saveClicked() {
        const {session} = this.props;
        const {cantPlayDates} = this.state;
        if (session) {
            this.setState({loading: true});
            try {
                await updateCantPlayDates(session.sessionId, cantPlayDates);
                this.goBack();
            } catch (error:any) {
                console.log('error caught:' + error);
                console.log('error type:' + error.type);
                switch (error.type) {
                    default:
                        this.setState({errorMessage: "", fatalError: true, loading: false});
                        break;
                }
            }
        }
    }

    goBack() {
        this.setState({redirectTo: this.props.redirectTo});
    }
}
