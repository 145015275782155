import * as React from "react"
import {KeyboardEvent} from "react"
import {mobile} from "../App"

interface Props {
    onChange: (currentValue: string) => void
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
    autoFocus?: boolean
    invalid?: boolean
    placeHolder: string
    password?: boolean
    disabled?: boolean
    value?: string
    width?: string
    mobileWidth?: string
}

interface State {
    value: string
}

export default class TextInput extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : '',
        };
    }

    render() {
        const {width, mobileWidth} = this.props;
        const mobileStyle = {
            width: (mobileWidth ? mobileWidth : "80vw"),
            border: this.props.invalid ? "0.5rem solid red" : "0.5rem solid var(--buttonBg)"
        };
        const deskStyle = {
            width: (width ? width : "10rem"),
            border: this.props.invalid ? "0.3rem solid red" : "0.3rem solid var(--buttonBg)"
        };
        const style = mobile ? mobileStyle : deskStyle;

        return (
            <input type={this.props.password ? "password" : "text"} placeholder={this.props.placeHolder}
                   onChange={this.onChange}
                   disabled={this.props.disabled}
                   style={style}
                   value={this.state.value}
                   name={this.props.placeHolder} autoFocus={this.props.autoFocus} onKeyDown={this.props.onKeyDown}/>
        );
    }

    private onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        this.setState(
            {
                value: value
            });
        this.props.onChange(value);
    }
}