import * as React from "react"
import {mobile} from "../App"
import Modal from "./Modal"
import {LinkButton} from "./LinkButton"

interface Props {
    text: string
    onOk: () => void
    onCancel: () => void
}

export default class ConfirmModal extends React.Component<Props> {

    render() {
        return (
            <Modal>
                <span style={{fontSize: mobile ? "2.5rem" : "1rem"}}>{this.props.text}</span>
                <div style={{
                    position: "absolute", right: 0, bottom: 0, width: "100%",
                    display: "flex", justifyContent: "space-around", alignItems: "center"
                }}>
                    <LinkButton style={{fontSize: mobile ? "3rem" : "1rem", textAlign: "right"}} onClick={this.props.onCancel}/>
                    <button onClick={this.props.onOk}> Okej</button>
                </div>
            </Modal>
        );
    }
}