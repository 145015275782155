import * as React from "react"
import {mobile} from "../App"

interface Props {
    onChange: (currentValue: string, valid: boolean) => void
    autoFocus?: boolean
    value?: string
}

interface State {
    valid: boolean
    value: string
}

export default class TimeInput extends React.Component<Props, State> {

    TIME_REGEXP = /^$|^(([01][0-9])|(2[0-3]))[0-5][0-9]$/;

    constructor(props: Props) {
        super(props);
        const value = this.props.value ? this.props.value : '';
        this.state = {
            value: value,
            valid: this.validate(value)
        };
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const mobileStyle = {
            width: "10rem",
            border: this.state.valid ? "0.5rem solid var(--buttonBg)" : "0.5rem solid red"
        };
        const deskStyle = {
            width: "2.5rem",
            border: this.state.valid ? "0.3rem solid var(--buttonBg)" : "0.3rem solid red"
        };
        const style = mobile ? mobileStyle : deskStyle;
        return (
            <input type="text" placeholder={"ttmm"} onChange={this.onChange}
                   value={this.state.value}
                   style={style}
                   name={"ttmm"} autoFocus={this.props.autoFocus} />
        );
    }

    onChange(event: React.FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        const valid = this.validate(value);
        this.setState(
            {
                value: value,
                valid: valid
            });
        this.props.onChange(value, valid);
    }

    validate(value: string): boolean {
        return value !== '' && this.TIME_REGEXP.test(value);
    }
}