import {PlayerDto} from "../../../api/dtos";

export default class PlayerDtoSorter {

    static compare(p1: PlayerDto, p2: PlayerDto): number {
        if (p1.ongoingRoundInfo && p2.ongoingRoundInfo) {
            //both plays in ongoing round
            if (p1.ongoingRoundInfo.groupNumber === p2.ongoingRoundInfo.groupNumber) {
                //both plays in same group on ongoing round, compare position
                return p1.ongoingRoundInfo.position - p2.ongoingRoundInfo.position;
            }
            return p1.ongoingRoundInfo.groupNumber - p2.ongoingRoundInfo.groupNumber;
        }
        if (!p1.ongoingRoundInfo && p2.ongoingRoundInfo && p2.groupNumber) {
            if (p2.ongoingRoundInfo.groupNumber > p2.groupNumber) {
                return -1;
            }
            return 1;
        }
        if (p1.ongoingRoundInfo && !p2.ongoingRoundInfo && p1.groupNumber) {
            if (p1.ongoingRoundInfo.groupNumber > p1.groupNumber) {
                return 1;
            }
            return -1;
        }
        if (!p1.ongoingRoundInfo && !p2.ongoingRoundInfo) {
            //both new, sort alphabetically
            return p1.account.firstName.localeCompare(p2.account.firstName);
        }
        console.log("BUG! p1:" + p1 + "p2:" + p2);
        return 0;
    }

}