import * as React from "react"
import {mobile} from "../App"

interface Props {
    heading?: string
}

export default class Table extends React.Component<Props> {
    render() {

        const fontSize = mobile ? "2rem" : "1rem";
        return (<>
                {this.props.heading &&
                <span style={{margin: "0.75rem 0 -0.25rem 0.75rem", fontSize: fontSize}}>{this.props.heading}</span>}
                <div style={{
                    width: "max-content",
                    backgroundColor: "var(--panelBg)",
                    color: "var(--panelText)",
                    margin: "0.5rem auto 0 auto",
                    borderRadius: "0.3rem",
                    padding: "0.1rem 0 0.1rem 0"
                }}>
                    {this.props.children}
                </div>
            </>
        );
    }
}