import {
    AccountDto,
    BillingDto,
    BookingIdDto,
    BookingSettingsDto,
    BookingsSummaryDto,
    BookingsWithTimeRatingsDto,
    BookingSystemSettingsDto,
    ContactsGroupDto,
    CourtNameAliasDto,
    EmailDto,
    ForgotPasswordRequestDto,
    GenerateScheduleStatusDto,
    GroupplayDto,
    GroupplaySettingsDto,
    InviteDto,
    InviteRequestDto,
    LoginRequestDto,
    MatchDetailsDto,
    NewAccountDto,
    NewAccountWithInviteDto,
    NextRoundBookingsDto,
    PlayersDto,
    PlayerStateDto,
    ResetPasswordLinkInfoDto,
    ResetPasswordRequestDto,
    RoundOverviewDto,
    RoundStandingsDto,
    RuleParagraphDto,
    RulesDto,
    SaveResultDto,
    ScheduleDto, SendSignupMailsRequestDto,
    SessionDto, SignupAnswer, SignupDto,
    TestBookingSystemDto,
    TimePlaceRequestDto,
    TimeRatingDto,
    UpdateAccountDataDto,
    UpdateNextRoundBookingsDto,
    UpdatePlayerDto,
    UpdatePlayerGroupDto,
    UpdateRoundOpenUntilDto
} from "./dtos"
import {Exception} from "./Exception"

const endPoint = '/api';

export const login = async (request: LoginRequestDto): Promise<SessionDto> => {
    return handleResponsePromise(post(request, '/session/login'));
};

export const logout = async (sessionId: string): Promise<any> => {
    return handleResponsePromise(post(null, '/session/logout', sessionId));
};

export const newAccount = async (request: NewAccountDto): Promise<any> => {
    return handleResponsePromise(post(request, '/account/new'));
};

export const forgotPassword = async (request: ForgotPasswordRequestDto): Promise<any> => {
    return handleResponsePromise(post(request, '/account/forgotPassword'));
};

export const resetPasswordInfo = async (linkId: string): Promise<ResetPasswordLinkInfoDto> => {
    return handleResponsePromise(get('/account/resetPassword/' + linkId));
};

export const resetPassword = async (request: ResetPasswordRequestDto): Promise<SessionDto> => {
    return handleResponsePromise(post(request, '/account/resetPassword'));
};

export const searchAccount = async (groupplayId: string, searchString: string, sessionId: string): Promise<AccountDto[]> => {
    return handleResponsePromise(post({ searchString: searchString}, '/groupplay/' + groupplayId + '/searchAccount/', sessionId));
}

export const getAccountData = async (sessionId: string): Promise<AccountDto> => {
    return handleResponsePromise(get('/account', sessionId));
};

export const updateAccountData = async (request: UpdateAccountDataDto, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/account/update', sessionId));
};

export const getTimeRatings = async (sessionId: string): Promise<TimeRatingDto[]> => {
    return handleResponsePromise(get('/account/timeRatings', sessionId));
};

export const saveTimeRatings = async (ratings: TimeRatingDto[], sessionId: string): Promise<any> => {
    return handleResponsePromise(post(ratings, '/account/timeRatings', sessionId));
};

export const getCantPlayDates = async (sessionId: string): Promise<string[]> => {
    return handleResponsePromise(get('/account/cantPlayDates', sessionId));
};

export const updateCantPlayDates = async (sessionId: string, dates: string[]): Promise<any> => {
    return handleResponsePromise(post(dates,'/account/cantPlayDates', sessionId));
};

export const verifyMail = async (linkId: string): Promise<SessionDto> => {
    return handleResponsePromise(post({linkId: linkId}, '/account/verifyMail'));
};

export const touchSession = async (sessionId: string): Promise<SessionDto> => {
    return handleResponsePromise(get('/session/touch', sessionId));
};

export const getGroupplay = async (groupplayId: string): Promise<GroupplayDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId));
};

export const getGroupplayList = async (): Promise<GroupplayDto[]> => {
    return handleResponsePromise(get('/groupplay/list'));
};

export const getPlayers = async (groupplayId: string, sessionId: string): Promise<PlayersDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/players', sessionId));
};

export const updatePlayerGroups = async (players: UpdatePlayerGroupDto[], groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(players, '/groupplay/' + groupplayId + '/playerGroups', sessionId));
};

export const updatePlayer = async (player: UpdatePlayerDto, groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(player, '/groupplay/' + groupplayId + '/player', sessionId));
};

export const invite = async (groupplayId: string, request: InviteRequestDto, sessionId: string): Promise<InviteDto> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/invite', sessionId));
};

export const addPlayer = async (groupplayId: string, email: EmailDto, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(email, '/groupplay/' + groupplayId + '/nextRound/addPlayer', sessionId));
};

export const removePlayer = async (groupplayId: string, accountId: number, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(null, '/groupplay/' + groupplayId + '/nextRound/remove/' + accountId, sessionId));
};

export const getInvite = async (linkId: string, sessionId?: string): Promise<InviteDto> => {
    return handleResponsePromise(post({linkId: linkId}, '/groupplay/invite', sessionId));
};

export const adminAcceptInvite = async (groupplayId: string, linkId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(null, '/groupplay/' + groupplayId + '/invite/' + linkId, sessionId));
};

export const createAccountWithInvite = async (request: NewAccountWithInviteDto): Promise<SessionDto> => {
    return handleResponsePromise(post(request, '/groupplay/createAccountWithInvite'));
};

export const deleteInvite = async (groupplayId: string, linkId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post({ linkId: linkId }, '/groupplay/' + groupplayId + '/deleteInvite', sessionId));
};

export const getPlayerState = async (groupplayId: string, sessionId: string): Promise<PlayerStateDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/playerState', sessionId));
};

export const getNextRoundBookings = async (groupplayId: string, sessionId: string): Promise<NextRoundBookingsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/nextRound/bookings', sessionId));
};

export const saveNextRoundBookings = async (request: UpdateNextRoundBookingsDto, groupplayId: string, sessionId: string): Promise<NextRoundBookingsDto> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/nextRound/bookings', sessionId));
};

export const getGenerateScheduleStatus = async (groupplayId: string, sessionId: string): Promise<GenerateScheduleStatusDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/nextRound/status', sessionId));
};

export const getNextRoundBookingsSummary = async (groupplayId: string, sessionId: string): Promise<BookingsSummaryDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/nextRound/bookingsSummary', sessionId));
};

export const generateNewSchedule = async (groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(null, '/groupplay/' + groupplayId + '/nextRound/generateSchedule', sessionId));
};

export const newRound = async (groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(null, '/groupplay/' + groupplayId + '/newRound', sessionId));
};

export const getSchedule = async (groupplayId: string, sessionId?: string): Promise<ScheduleDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/schedule', sessionId));
};

export const getScheduleForRound = async (groupplayId: string, roundNr: number, sessionId?: string): Promise<ScheduleDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/schedule/' + roundNr, sessionId));
};

export const getRounds = async (groupplayId: string): Promise<RoundOverviewDto[]> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/rounds'));
};

export const getMatchDetails = async (groupplayId: string, matchId: string, sessionId: string): Promise<MatchDetailsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/match/' + matchId, sessionId));
};

export const saveMatchResult = async (request: SaveResultDto, matchId: number, groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/match/' + matchId + '/result', sessionId));
};

export const getBookingSettings = async (groupplayId: string, sessionId: string): Promise<BookingSettingsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/bookingSettings', sessionId));
};

export const saveBookingSettings = async (request: BookingSettingsDto, groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/bookingSettings', sessionId));
};

export const getCourtNameAliases = async (groupplayId: string, sessionId: string): Promise<CourtNameAliasDto[]> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/courtNameAlias', sessionId));
};

export const saveCourtNameAliases = async (request: CourtNameAliasDto[], groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/courtNameAlias', sessionId));
};

export const getGroupplaySettings = async (groupplayId: string, sessionId: string): Promise<GroupplaySettingsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/settings', sessionId))
}

export const saveGroupplaySettings = async (request: GroupplaySettingsDto, groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/settings', sessionId))
}

export const getBookingsSystemSettings = async (groupplayId: string, sessionId: string): Promise<BookingSystemSettingsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/bookingSystemSettings', sessionId));
};

export const saveBookingsSystemSettings = async (request: BookingSystemSettingsDto, groupplayId: string, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/bookingSystemSettings', sessionId));
};

export const testBookingSystem = async (groupplayId: string, sessionId: string): Promise<TestBookingSystemDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/testBookingSystem', sessionId));
};

export const getContacts = async (groupplayId: string, sessionId: string): Promise<ContactsGroupDto[]> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/contacts', sessionId));
};

export const getStandings = async (groupplayId: string, sessionId?: string): Promise<RoundStandingsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/standings', sessionId));
};

export const getStandingsForRound = async (groupplayId: string, round: number): Promise<RoundStandingsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/standings/' + round));
};

export const clearMatchTimePlace = async (groupplayId: string, matchId: number, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(null, '/groupplay/' + groupplayId + '/match/' + matchId + '/clearMatchTimePlace', sessionId));
};

export const getFreeBookings = async (groupplayId: string, matchId: number, sessionId: string): Promise<BookingsWithTimeRatingsDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/match/' + matchId + '/getFreeBookings', sessionId));
};

export const newBookingForMatch = async (groupplayId: string, matchId: number, bookingdIdDto: BookingIdDto, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(bookingdIdDto, '/groupplay/' + groupplayId + '/match/' + matchId + '/newBooking', sessionId));
};

export const updateRoundOpenUntil = async (groupplayId: string, roundNr: number, request: UpdateRoundOpenUntilDto, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/round/' + roundNr + '/openUntil', sessionId));
};

export const getRuleText = async (groupplayId: string): Promise<RuleParagraphDto[]> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/ruletext'));
};

export const saveRuleText = async (groupplayId: string, rules: RuleParagraphDto[], sessionId: string): Promise<any> => {
    return handleResponsePromise(post(rules, '/groupplay/' + groupplayId + '/ruletext', sessionId));
};

export const getRules = async (groupplayId: string, sessionId: string): Promise<RulesDto> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/nextRound/rules', sessionId));
};

export const saveRules = async (groupplayId: string, rules: RulesDto, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(rules, '/groupplay/' + groupplayId + '/nextRound/rules', sessionId));
};

export const saveOwnBookingForMatch = async (groupplayId: string, matchId: number, timePlace: TimePlaceRequestDto, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(timePlace, '/groupplay/' + groupplayId + '/match/' + matchId + "/ownBooking", sessionId));
};

export const getBilling = async (groupplayId: string, roundNr: number, sessionId: string): Promise<BillingDto[]> => {
    return handleResponsePromise(get('/groupplay/' + groupplayId + '/billing/' + roundNr, sessionId));
};

export const sendSignupMails = async (groupplayId: string, request: SendSignupMailsRequestDto, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(request, '/groupplay/' + groupplayId + '/sendSignups', sessionId));
};

export const getSignupLink = async (groupplayId: string, linkId: string, sessionId?: string): Promise<SignupDto> => {
    return handleResponsePromise(post({ linkId: linkId}, '/groupplay/' + groupplayId + '/signuplink', sessionId));
};

export const signup = async (groupplayId: string, answer: SignupAnswer, sessionId: string): Promise<any> => {
    return handleResponsePromise(post(null, '/groupplay/' + groupplayId + '/signup/' + answer, sessionId));
};

const post = (request: any, path: string, sessionId?: string): Promise<Response> => {
    const headers: Headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    if (sessionId) {
        headers.set('sessionId', sessionId);
    }

    return fetch(endPoint + path, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(request)
    });
};

const get = (path: string, sessionId?: string): Promise<Response> => {
    const headers: Headers = new Headers();
    headers.set('Accept', 'application/json');
    if (sessionId) {
        headers.set('sessionId', sessionId);
    }
    return fetch(endPoint + path, {headers: headers});
};

const handleResponsePromise = <T>(promise: Promise<Response>): Promise<T> => {
    return new Promise((resolve, reject) => {
        let response: Response;
        promise
            .catch(error => {
                return Promise.reject(new Exception('NETWORK_ERROR'))
            })
            .then(rawResponse => {
                response = rawResponse;
                try {
                    return response.text();
                } catch (e) {
                    console.log('textError:' + e);
                    return Promise.reject(new Exception('TEXT_ERROR'))
                }
            })
            .then(responseAsText => {
                try {
                    if (response.status === 204) {
                        return null;
                    }
                    const parsedJson = JSON.parse(responseAsText);
                    if (response.ok) {
                        return parsedJson;
                    }

                    if (response.status >= 400) {
                        if (parsedJson.type) {
                            return Promise.reject(new Exception(parsedJson.type, parsedJson.errorMessage))
                        } else {
                            return Promise.reject(new Exception('UNKNOWN_ERROR'))
                        }
                    }
                    return Promise.reject(new Exception('UNKNOWN_ERROR'))
                } catch (e) {
                    if (response.status >= 500) {
                        return Promise.reject(new Exception('SERVER_ERROR'))
                    }
                    return Promise.reject(new Exception('JSON_PARSE_ERROR'))
                }
            })
            .then(body => {
                resolve(body);
            })
            .catch(err => {
                reject(err);
            })
    });
};
