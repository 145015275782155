import * as React from "react"
import {mobile} from "../App"

interface Props {
    onClose?: () => void
    height?: string
}

export default class Modal extends React.Component<Props> {
    render() {
        return (
            <>
                <div
                    style={{position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.7)", zIndex: 10}}/>
                <div style={{
                    position: "fixed", background: "var(--panelTableLine)",
                    width: mobile ? "40rem" : "20rem",
                    height: this.props.height ? this.props.height : (mobile ? "20rem" : "6rem"),
                    top: "50%",
                    left: "50%", transform: "translate(-50%, -50%)",
                    borderRadius: "0.3rem", padding: "0.5rem", zIndex: 2000
                }}>
                    {this.props.children}
                    {this.props.onClose &&
                        <button style={{position: "absolute", right: 0, bottom: 0}}
                                onClick={this.props.onClose}> Stäng </button>}
                </div>
            </>
        )
    }
}