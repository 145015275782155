import * as React from "react"
import {RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../../BasePage"
import {BookingDto, DateTimeDto, NextRoundBookingsDto, TimePlaceRequestDto, UpdateNextRoundBookingsDto} from "../../../api/dtos"
import {getNextRoundBookings, saveNextRoundBookings} from "../../../api/api"
import BookingsTable from "./BookingsTable"
import NumberInput from "../../../components/NumberInput"
import FullPageMessage from "../../../components/FullPageMessage"
import {mobile} from "../../../App"
import FullPageSpinner from "../../../components/FullPageSpinner"
import BookingsSummaryTable from "./BookingsSummaryTable"

interface State {
    fatalError: boolean
    loading: boolean
    loadingBookings: boolean
    nrOfMatches: number
    maxNrOfMatchesForPlayer: number
    minNrOfMatchesForPlayer: number
    originalBookings: BookingDto[]
    bookings: BookingDto[]
    originalNrOfExtraBookings: number
    prevRoundEnd?: DateTimeDto
    nrOfExtraBookings: number
    saveButtonDisabled: boolean
    errorMessage?: string
}

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>


export default class AdminBookingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: false,
            loadingBookings: true,
            nrOfMatches: 0,
            maxNrOfMatchesForPlayer: 0,
            minNrOfMatchesForPlayer: 0,
            bookings: [],
            nrOfExtraBookings: 0,
            originalBookings: [],
            originalNrOfExtraBookings: 0,
            saveButtonDisabled: true
        };
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params;
        if (this.props.session) {
            try {
                const nextRoundBookingsDto: NextRoundBookingsDto = await getNextRoundBookings(groupplayId, this.props.session.sessionId);
                this.setState({
                    nrOfMatches: nextRoundBookingsDto.summary.nrOfMatches,
                    nrOfExtraBookings: nextRoundBookingsDto.summary.nrOfExtraBookings,
                    maxNrOfMatchesForPlayer: nextRoundBookingsDto.summary.maxNrOfMatchesForPlayer,
                    minNrOfMatchesForPlayer: nextRoundBookingsDto.summary.minNrOfMatchesForPlayer,
                    bookings: nextRoundBookingsDto.bookings,
                    originalBookings: Object.assign([], nextRoundBookingsDto.bookings),
                    originalNrOfExtraBookings: nextRoundBookingsDto.summary.nrOfExtraBookings,
                    prevRoundEnd: nextRoundBookingsDto.summary.prevRoundEnd
                })
            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    case "BOOKING_SYSTEM_CONFIG_NOT_FOUND":
                        this.setState({errorMessage: "Det finns ingen konfiguration för bokningssystem!"});
                        break;
                    case "COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM":
                        this.setState({errorMessage: "Problem att kontakta bokningssystemet: " + error.message})
                        break;
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
            this.setState({loading: false, loadingBookings: false});
        }
    }

    render() {
        const fontSize = mobile ? "2rem" : "1rem";
        const {bookings, nrOfMatches, nrOfExtraBookings, maxNrOfMatchesForPlayer, minNrOfMatchesForPlayer, prevRoundEnd} = this.state;
        const nrOfAvailableBookings = bookings.filter(b => !b.dontUse).length;
        const maxNrOfExtraBookings = Math.max((nrOfAvailableBookings - nrOfMatches), 0);

        if (this.state.errorMessage) {
            return (<BasePage {...this.props} adminPage={true} loading={this.state.loading}
                              fatalError={this.state.fatalError}>
                <FullPageMessage text={this.state.errorMessage}/>
            </BasePage>);
        }
        if(this.props.session && this.state.loadingBookings) {
            return (<FullPageSpinner text="Hämtar bokningar från bokningssystemet.."/>);
        }
        return (
            <BasePage {...this.props} adminPage={true} loading={this.state.loading} fatalError={this.state.fatalError}>
                <BookingsSummaryTable summary={{
                    nrOfAvailableBookings: nrOfAvailableBookings,
                    firstBooking: this.firstBooking(),
                    lastBooking: this.lastBooking(),
                    maxNrOfMatchesForPlayer: maxNrOfMatchesForPlayer,
                    minNrOfMatchesForPlayer: minNrOfMatchesForPlayer,
                    nrOfExtraBookings: nrOfExtraBookings,
                    nrOfMatches: nrOfMatches,
                    prevRoundEnd: prevRoundEnd
                }} />

                <div style={{textAlign: "center", fontSize: fontSize}}>
                    Extra tider:
                    <NumberInput onChange={this.nrOfExtraBookingsChanged}
                                 value={nrOfExtraBookings} min={0} max={maxNrOfExtraBookings}/>
                </div>
                <BookingsTable bookings={this.state.bookings} nrOfExtraBookings={this.state.nrOfExtraBookings}
                               nrOfMatches={this.state.nrOfMatches} bookingChanged={this.bookingChanged}/>
                <div style={{
                    padding: "0.5rem", backgroundColor: "var(--pageBg)",
                    position: "fixed", bottom: 0, right: 0
                }}>
                    <button onClick={this.handleSaveClicked} disabled={this.state.saveButtonDisabled}
                            style={{width: mobile ? "20rem" : "6rem"}}>Spara
                    </button>
                </div>
            </BasePage>
        );
    }

    private firstBooking = (): BookingDto | undefined => {
        for (const booking of this.state.bookings) {
            if (!booking.dontUse) {
                return booking;
            }
        }
    }

    private lastBooking = (): BookingDto | undefined => {
        const {bookings, nrOfMatches, nrOfExtraBookings} = this.state;
        let counter = 0;
        for (let i = 0; i < bookings.length; i++) {
            if (!bookings[i].dontUse) {
                counter++;
                if (counter === (nrOfMatches + nrOfExtraBookings)) {
                    return bookings[i];
                }
            }
        }
    }
    private bookingChanged = (booking: BookingDto) => {
        const bookings = this.state.bookings.slice();

        for (let i = 0; i < bookings.length; i++) {
            if (bookings[i].id === booking.id) {
                bookings[i] = booking;
            }
        }
        const saveButtonDisabled = this.saveButtonDisabled(this.state.nrOfExtraBookings, bookings);
        const nrOfBookings = bookings.filter(b => !b.dontUse).length;
        if((this.state.nrOfMatches + this.state.nrOfExtraBookings) > nrOfBookings) {
            this.setState({nrOfExtraBookings: Math.max((nrOfBookings-this.state.nrOfMatches), 0),
                bookings: bookings, saveButtonDisabled: saveButtonDisabled })
        } else {
            this.setState({bookings: bookings, saveButtonDisabled: saveButtonDisabled});
        }
    }

    private nrOfExtraBookingsChanged = (value: number) => {
        const saveButtonDisabled = this.saveButtonDisabled(value, this.state.bookings);
        this.setState({nrOfExtraBookings: value, saveButtonDisabled: saveButtonDisabled});
    }

    private saveButtonDisabled = (nrOfExtraBookings: number, bookings: BookingDto[]): boolean => {
        if (this.state.originalNrOfExtraBookings !== nrOfExtraBookings) {
            return false;
        }
        const {originalBookings} = this.state;
        for (let booking of bookings) {
            for (let orgBooking of originalBookings) {
                if (booking.id === orgBooking.id && booking.dontUse !== orgBooking.dontUse) {
                    return false;
                }
            }
        }
        return true;
    }

    private handleSaveClicked = async () => {
        if (this.props.session) {
            this.setState({loading: true});
            const {groupplayId} = this.props.match.params;
            const {nrOfExtraBookings} = this.state;
            const dontUse: TimePlaceRequestDto[] = [];
            for (const booking of this.state.bookings) {
                if (booking.dontUse) {
                    dontUse.push({
                        date: booking.time.date,
                        time: booking.time.hour,
                        court: booking.court
                    });
                }
            }
            try {
                const saveRequest: UpdateNextRoundBookingsDto = {
                    dontUse: dontUse,
                    nrOfExtraBookings: nrOfExtraBookings
                };
                await saveNextRoundBookings(saveRequest, groupplayId, this.props.session.sessionId);
                this.setState({
                    saveButtonDisabled: true,
                    originalBookings: Object.assign([], this.state.bookings),
                    loading: false
                });
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }
}