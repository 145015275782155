import * as React from "react"
import {CSSProperties} from "react"
import {BookingDto, BookingsSummaryDto} from "../../../api/dtos"
import DateUtil from "../../../util/DateUtil"
import {mobile} from "../../../App"

interface Props {
    summary: BookingsSummaryDto
    showExtraTimes?: boolean
}

export default class BookingsSummaryTable extends React.Component<Props> {

    render() {
        const {summary, showExtraTimes} = this.props;
        const fontSize = mobile ? "2rem" : "1rem";
        const notEnoughBookings = summary.nrOfAvailableBookings < summary.nrOfMatches;
        const days = this.daysBetween(summary.firstBooking, summary.lastBooking);

        const nrOfBookingsRowStyle: CSSProperties = notEnoughBookings ? {color: "red", fontWeight: "bold"} :
            {};
        return (
            <table className="invisible" style={{margin: "auto", fontSize: fontSize}}>
                <tbody>
                <tr>
                    <td style={{textAlign: "right"}}>Antal matcher:</td>
                    <td>{summary.nrOfMatches}</td>
                </tr>
                {showExtraTimes && <tr>
                    <td style={{textAlign: "right"}}>Extra tider:</td>
                    <td>{summary.nrOfExtraBookings}</td>
                </tr>
                }
                {summary.prevRoundEnd && <tr>
                    <td style={{textAlign: "right"}}>Tidigare omg. slut:</td>
                    <td>{DateUtil.shortDayName(summary.prevRoundEnd.day) + " " + summary.prevRoundEnd.date + " kl " + DateUtil.format(summary.prevRoundEnd.hour, summary.prevRoundEnd.minute)}</td>
                </tr>}
                <tr>
                    <td style={{textAlign: "right"}}>Första tid:</td>
                    <td>{summary.firstBooking ? DateUtil.shortDayName(summary.firstBooking.time.day) + " " + summary.firstBooking.time.date + " kl " + summary.firstBooking.time.hour : "-"}</td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Sista tid:</td>
                    <td>{summary.lastBooking ? DateUtil.shortDayName(summary.lastBooking.time.day) + " " + summary.lastBooking.time.date + " kl " + summary.lastBooking.time.hour : "-"}</td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Omgångens längd:</td>
                    <td>{days ? days + " dagar" : "-"}</td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Matcher/spelare:</td>
                    <td>{summary.minNrOfMatchesForPlayer === summary.maxNrOfMatchesForPlayer ?
                        summary.minNrOfMatchesForPlayer :
                        summary.minNrOfMatchesForPlayer + "-" + summary.maxNrOfMatchesForPlayer}</td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Min Ø dagar mellan match:</td>
                    <td>{days ? (days / summary.maxNrOfMatchesForPlayer).toFixed(1) : "-"}</td>
                </tr>
                <tr>
                    <td style={{textAlign: "right"}}>Max Ø dagar mellan match:</td>
                    <td>{days ? (days / summary.minNrOfMatchesForPlayer).toFixed(1) : "-"}</td>
                </tr>
                <tr style={nrOfBookingsRowStyle}>
                    <td style={{textAlign: "right"}}>Tillgängliga bookningar:</td>
                    <td>{summary.nrOfAvailableBookings} (inom 90 dagar)</td>
                </tr>
                </tbody>
            </table>);
    }

    private daysBetween(b1?: BookingDto, b2?: BookingDto): number | undefined {
        if (b1 && b2) {
            const d1: Date = new Date(b1.time.date);
            const d2: Date = new Date(b2.time.date);
            return DateUtil.daysBetween(d1, d2);
        }
    }
}