import * as React from "react"
import {getGroupplayList} from "../../api/api"
import {GroupplayDto} from "../../api/dtos"
import {Link} from "react-router-dom"
import {mobile} from "../../App"

interface Props {
    onFatalError: () => void
}

interface State {
    groupplays: GroupplayDto[]
}

class GroupplayList extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            groupplays: []
        }
    }

    async componentDidMount() {
        try {
            const list = await getGroupplayList()
            this.setState({
                groupplays: list
            })
        } catch (error) {
            this.props.onFatalError()
        }
    }

    render() {
        const fontSize = mobile ? "2rem" : "1rem"
        return (
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
            }}>

                {this.state.groupplays.length > 0 &&
                    <ul style={{fontSize: fontSize}}>
                        {this.state.groupplays
                            .map(grp =>
                                <li key={grp.id} style={{listStyle: "none"}}>
                                    <Link to={"/gruppspel/" + grp.id} key={grp.id}>{grp.name}</Link>
                                </li>
                            )}
                    </ul>
                }
            </div>
        )
    }
}

export default GroupplayList
