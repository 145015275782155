import * as React from "react"
import {KeyboardEvent} from "react"
import {mobile} from "../App"
import {AccountDto} from "../api/dtos";
import {searchAccount} from "../api/api";

interface Props {
    onChange: (account?: AccountDto) => void
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
    autoFocus?: boolean
    disabled?: boolean
    value?: AccountDto
    groupplayId: string
    sessionId: string
}

interface State {
    searchString: string
    searchResult: AccountDto[]
}

export default class TypeAheadInput extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            searchString: '',
            searchResult: []
        };
    }

    render() {
        const validInput = this.props.value !== undefined || this.state.searchString === '';
        const mobileStyle = {
            width: "80vw",
            fontSize: "4rem",
            border: validInput ? "0.5rem solid var(--buttonBg)" : "0.5rem solid red"
        };
        const deskStyle = {
            width: "10rem",
            border: validInput ? "0.3rem solid var(--buttonBg)" : "0.3rem solid red"
        };

        const style = mobile ? mobileStyle : deskStyle;
        return (
            <>
                <input type="text" placeholder={"search.."}
                       onChange={this.onChange}
                       disabled={this.props.disabled}
                       style={style}
                       value={this.state.searchString}
                       name="E-mail" autoFocus={this.props.autoFocus} onKeyDown={this.props.onKeyDown}/>
                <ul className="typeahead" style={{}}>
                    {this.state.searchResult.map(a =>
                        <li style={{}} key={a.id} onClick={() => this.onDropdownClick(a)}>
                            {a.firstName} {a.lastName}
                        </li>)}
                </ul>
            </>
        );
    }

    private onDropdownClick = (account: AccountDto) => {
        this.setState({searchResult: [], searchString: account.firstName + ' ' + account.lastName});
        this.props.onChange(account)
    }

    private onChange = async (event: React.FormEvent<HTMLInputElement>) => {
        const theSearchString = event.currentTarget.value;
        console.log(theSearchString);
        if (theSearchString.length > 2) {
            let searchResponse = await searchAccount(this.props.groupplayId, theSearchString, this.props.sessionId);
            console.log(searchResponse);
            this.setState( { searchString: theSearchString, searchResult: searchResponse})
        } else {
            this.setState({searchString: theSearchString, searchResult: []});
        }
        this.props.onChange(undefined);
    };
}
