import * as React from "react"
import {InviteDto} from "../../../api/dtos";
import Table from "../../../components/Table";
import TableRow from "../../../components/TableRow";
import TableText from "../../../components/TableText";
import TableButton, {ButtonType} from "../../../components/TableButton";
import ConfirmModal from "../../../components/ConfirmModal";

interface Props {
    invites: InviteDto[]
    deleteInvite: (linkId: string) => void
    addInvite: (linkId: string) => void
}

interface State {
    deleteInvite?: InviteDto
}

class InvitesTable extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const {deleteInvite} = this.state
        const {invites} = this.props;
        return (<>
            {deleteInvite && <ConfirmModal
                text={"Vill du radera inbjudan till " + deleteInvite.firstName + " " + deleteInvite.lastName + "? (länken i mailet kommer inte längre fungera)"}
                onCancel={() => this.setState({deleteInvite: undefined})}
                onOk={() => this.deleteInvite(deleteInvite.linkId)}/>}
            {invites.length > 0 &&
            <Table heading="Inbjudningar">
                {invites.map((invite, i) =>
                    <TableRow key={invite.linkId} lastRow={invites.length === (i + 1)}>
                        <TableText left="0" mobLeft="0"
                                   mobFirstRow>{invite.firstName + ' ' + invite.lastName}</TableText>
                        <TableText left="18rem" mobLeft="0" mobSecondRow>{invite.email}</TableText>
                        <TableText left="35rem" mobLeft="30rem" mobFirstRow>{invite.created.date}</TableText>
                        {invite.hasAccount &&
                        <TableButton type={ButtonType.ADD} right="5rem" mobRight="4rem"
                                     onClick={() => this.props.addInvite(invite.linkId)}/>}
                        <TableButton type={ButtonType.REMOVE} right="0" mobRight="-1.25rem"
                                     onClick={() => this.setState({deleteInvite: invite})}/>
                    </TableRow>
                )}
            </Table>}
            </>);
    }

    private deleteInvite = (linkId : string) => {
        this.props.deleteInvite(linkId);
        this.setState({deleteInvite: undefined});
    }
}

export default InvitesTable;