import * as React from "react"
import DateUtil from "../../util/DateUtil"
import {MatchDto, SessionDto, SetResultDto} from "../../api/dtos"
import Table from "../../components/Table"
import TableText from "../../components/TableText"
import TableRow from "../../components/TableRow"
import {mobile} from "../../App"
import TableButton, {ButtonType} from "../../components/TableButton"
import TennisBall from "../../components/TennisBall"

interface Props {
    matches: MatchDto[]
    groupplayId: string
    session?: SessionDto
}

class Schedule extends React.Component<Props> {
    render() {
        const matches = this.props.matches;
        const nrOfMatches = matches.length;
        return (
            <Table>
                {matches.map((match, i) =>
                    this.getMatch(match, nrOfMatches === (i + 1)))}
            </Table>
        );
    }

    getMatch(match: MatchDto, lastMatch: boolean) {
        const player1String = match.player1.firstName + " " + match.player1.lastName;
        const player2String = match.player2.firstName + " " + match.player2.lastName;
        const player1Color = (match.result && (match.result.player1Wo || match.result.player1WoNoShow)) ? "var(--buttonDisabled)" : "var(--panelText)"
        const player2Color = (match.result && (match.result.player2Wo || match.result.player2WoNoShow)) ? "var(--buttonDisabled)" : "var(--panelText)"
        return (
            <TableRow key={"match-" + match.id} lastRow={lastMatch}>
                {match.timePlace && <>
                    <TableText left="0.5rem" mobLeft="0.5rem" mobFirstRow>{DateUtil.shortDayName(match.timePlace.time.day)}</TableText>
                    <TableText left="2rem" mobLeft="3.5rem" mobFirstRow>{match.timePlace.time.date}</TableText>
                    <TableText left="8rem" mobLeft="0.5rem" mobSecondRow>kl {match.timePlace.time.hour}</TableText>
                    <TableText left="11rem" mobLeft="6rem" mobSecondRow>{match.timePlace.court}</TableText>
                    {!mobile && <TableText left="15rem">Grp {match.groupNr}</TableText>}
                </>}
                {mobile ?
                    <>
                        <TableText mobLeft="15rem" mobFirstRow color={player1Color}>{player1String} {this.getTennisBall(match.player1.id, match.ballPlayer ===1)}</TableText>
                        <TableText mobLeft="15rem" mobSecondRow color={player2Color}>{player2String} {this.getTennisBall(match.player2.id, match.ballPlayer ===2)}</TableText>
                    </>
                    :
                    <TableText left="18.5rem" mobLeft="" color={player1Color}>
                        <span style={{color: player1Color}}> {this.getTennisBall(match.player1.id, match.ballPlayer ===1)} {player1String} </span>
                        -
                        <span style={{color: player2Color}}> {player2String} {this.getTennisBall(match.player2.id, match.ballPlayer ===2)}</span>
                    </TableText>}
                {mobile ?
                    this.getMobileResults(match)
                    :
                    <TableText left="42rem">{match.resultString}</TableText>}
                {this.props.session && (match.movable || match.resultEditable) &&
                <TableButton type={ButtonType.HAMBURGER} right="0" mobRight="-0.6rem"
                             link={"/gruppspel/" + this.props.groupplayId + "/match/" + match.id}/>}

            </TableRow>
        );
    }

    private getTennisBall(playerId: number, ballPlayer : boolean) {
        const session = this.props.session;
        if(ballPlayer && session) {
            if(session.accountId === playerId) {
                return <TennisBall size={mobile?"2rem":"1rem"} />
            }
        }
    }

    private getMobileResults(match: MatchDto) {
        const result: JSX.Element[] = [];
        if (match.result) {
            if (match.result.player1Wo || match.result.player2Wo || match.result.player1WoNoShow || match.result.player2WoNoShow) {
                result.push(<TableText key={"wo-" + match.id} mobLeft="34rem">WO</TableText>);
            }

            for (let idx = 0; idx < match.result.sets.length; idx++) {
                const set: SetResultDto = match.result.sets[idx];
                const left = (2 * idx + 34) + "rem";
                result.push(<TableText key={"res-1-" + idx + "-" + match.id} mobLeft={left} mobFirstRow>
                    {set.player1Games}</TableText>);
                result.push(<TableText key={"res-2-" + idx + "-" + match.id} mobLeft={left} mobSecondRow>
                    {set.player2Games}</TableText>);
            }
        }
        return result;
    }
}

export default Schedule;