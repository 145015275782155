import * as React from "react"
import {RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../../BasePage"
import {mobile} from "../../../App"
import Panel from "../../../components/Panel"
import NumberInputWithLabel from "../../../components/NumberInputWithLabel"
import {RulesDto} from "../../../api/dtos"
import {getRules, saveRules} from "../../../api/api"

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    nrOfSets: number
    gamesPerSet: number
    tiebrakeGamesEqual: number
    pointsPerSet: number
    pointsPerGame: number
    pointsWoWinner: number
    pointsWoLoser: number
    pointsWoNoShowWinner: number
    pointsWoNoShowLoser: number
    pointsDrawSet: number
    pointsSetLeader: number
    pointsSetRunnerUp: number
    leaderByNrOfGames: number
    serverRules?: RulesDto
    loading: boolean
    fatalError: boolean
}

export default class RulesConfigPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            nrOfSets: 0,
            gamesPerSet: 0,
            tiebrakeGamesEqual: 0,
            pointsPerSet: 0,
            pointsPerGame: 0,
            pointsWoWinner: 0,
            pointsWoLoser: 0,
            pointsWoNoShowWinner: 0,
            pointsWoNoShowLoser: 0,
            pointsDrawSet: 0,
            pointsSetLeader: 0,
            pointsSetRunnerUp: 0,
            leaderByNrOfGames: 0,
            loading: true,
            fatalError: false
        };
    }

    async componentDidMount() {
        if (this.props.session) {
            try {
                const {groupplayId} = this.props.match.params;
                const rulesDto: RulesDto = await getRules(groupplayId, this.props.session.sessionId);
                this.setState({
                    serverRules: rulesDto,
                    nrOfSets: rulesDto.nrOfSets,
                    gamesPerSet: rulesDto.gamesPerSet,
                    tiebrakeGamesEqual: rulesDto.tiebrakeGamesEqual,
                    pointsPerSet: rulesDto.pointsPerSet/10,
                    pointsPerGame: rulesDto.pointsPerGame/10,
                    pointsWoWinner: rulesDto.pointsWoWinner/10,
                    pointsWoLoser: rulesDto.pointsWoLoser/10,
                    pointsWoNoShowWinner: rulesDto.pointsWoNoShowWinner/10,
                    pointsWoNoShowLoser: rulesDto.pointsWoNoShowLoser/10,
                    pointsDrawSet: rulesDto.pointsDrawSet/10,
                    pointsSetLeader: rulesDto.pointsSetLeader/10,
                    pointsSetRunnerUp: rulesDto.pointsSetRunnerUp/10,
                    leaderByNrOfGames: rulesDto.leaderByNrOfGames,
                    loading: false
                });
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        }
    }

    render() {
        const {
            loading, fatalError, nrOfSets, gamesPerSet, tiebrakeGamesEqual,
            pointsPerSet, pointsPerGame, pointsWoWinner, pointsWoLoser,
            pointsWoNoShowWinner, pointsWoNoShowLoser, pointsDrawSet, pointsSetLeader,
            pointsSetRunnerUp, leaderByNrOfGames
        } = this.state;
        const fontSize = mobile ? "3rem" : "1rem";
        return (
            <BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <div style={{margin: "auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Panel center={true}>
                        <span style={{
                            marginTop: "2rem",
                            fontSize: fontSize,
                            textAlign: "center",
                            textDecoration: "underline"
                        }}>
                        Match-struktur:
                    </span>
                        <NumberInputWithLabel label={"Antal Set:"} onChange={this.nrOfSetsChanged}
                                              value={nrOfSets} min={0} max={5}/>
                        <NumberInputWithLabel label={"Gem / Set:"} onChange={this.gamesPerSetChanged}
                                              value={gamesPerSet} min={1} max={6}/>
                        <NumberInputWithLabel label={"Tiebrake, vid lika gem:"}
                                              onChange={this.tiebrakeGamesEqualChanged}
                                              value={tiebrakeGamesEqual} min={1} max={6}/>
                        <span style={{
                            marginTop: "2rem",
                            fontSize: fontSize,
                            textAlign: "center",
                            textDecoration: "underline"
                        }}>
                            Poäng:
                        </span>
                        <NumberInputWithLabel label={"set-vinst:"} onChange={this.pointsPerSetChanged}
                                              value={pointsPerSet} min={0} max={10} step={0.5}/>
                        <NumberInputWithLabel label={"gem-vinst:"} onChange={this.pointsPerGameChanged}
                                              value={pointsPerGame} min={0} max={10} step={0.5}/>
                        <NumberInputWithLabel label={"lika eller ej påbörjat set"}
                                              onChange={this.pointsDrawSetChanged}
                                              value={pointsDrawSet} min={0} max={10} step={0.5}/>
                        <NumberInputWithLabel label={"set-ledare"} onChange={this.pointsSetLeaderChanged}
                                              value={pointsSetLeader} min={0} max={10} step={0.5}/>
                        <NumberInputWithLabel label={"underläge set:"}
                                              onChange={this.pointsSetRunnerUpChanged}
                                              value={pointsSetRunnerUp} min={0} max={10} step={0.5}/>
                        <NumberInputWithLabel label={"ledning med antal gem:"}
                                              onChange={this.leaderByNrOfGamesChanged}
                                              value={leaderByNrOfGames} min={1} max={gamesPerSet-1}/>
                        <NumberInputWithLabel label={"WO-vinst:"} onChange={this.pointsWoWinnerChanged}
                                              value={pointsWoWinner} min={0} max={10} step={0.5}/>
                        <NumberInputWithLabel label={"WO-förlust:"} onChange={this.pointsWoLoserChanged}
                                              value={pointsWoLoser} min={-10} max={0} step={0.5}/>
                        <NumberInputWithLabel label={"WO-vinst (oanmäld):"} onChange={this.pointsWoNoShowWinnerChanged}
                                              value={pointsWoNoShowWinner} min={0} max={10} step={0.5}/>
                        <NumberInputWithLabel label={"WO-förlust oanmäld):"} onChange={this.pointsWoNoShowLoserChanged}
                                              value={pointsWoNoShowLoser} min={-10} max={0} step={0.5}/>
                    </Panel>
                </div>
                <div style={{
                    padding: "0.5rem", backgroundColor: "var(--pageBg)",
                    position: "fixed", bottom: 0, right: 0
                }}>
                    <button onClick={this.handleSaveClicked} disabled={this.saveButtonDisabled()}
                            style={{width: mobile ? "20rem" : "6rem"}}>Spara
                    </button>
                </div>
            </BasePage>
        );
    }

    private handleSaveClicked = async () => {
        if (this.props.session) {
            try {
                this.setState({loading: true});
                const {
                    nrOfSets, gamesPerSet, tiebrakeGamesEqual, pointsPerSet, pointsPerGame,
                    pointsWoWinner, pointsWoLoser, pointsWoNoShowWinner, pointsWoNoShowLoser,
                    pointsDrawSet, pointsSetLeader, pointsSetRunnerUp, leaderByNrOfGames} = this.state;
                const rulesDto: RulesDto = {
                    nrOfSets: nrOfSets,
                    gamesPerSet: gamesPerSet,
                    tiebrakeGamesEqual: tiebrakeGamesEqual,
                    pointsPerSet: 10*pointsPerSet,
                    pointsPerGame: 10*pointsPerGame,
                    pointsWoWinner: 10*pointsWoWinner,
                    pointsWoLoser: 10*pointsWoLoser,
                    pointsWoNoShowWinner: 10*pointsWoNoShowWinner,
                    pointsWoNoShowLoser: 10*pointsWoNoShowLoser,
                    pointsDrawSet: 10*pointsDrawSet,
                    pointsSetLeader: 10*pointsSetLeader,
                    pointsSetRunnerUp: 10*pointsSetRunnerUp,
                    leaderByNrOfGames: leaderByNrOfGames
                }
                const {groupplayId} = this.props.match.params;
                await saveRules(groupplayId, rulesDto, this.props.session.sessionId);
                this.setState({serverRules: rulesDto, loading: false});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true, loading: false});
                        break;
                }
            }
        }
    };

    private saveButtonDisabled = (): boolean | undefined => {
        const {
            serverRules, nrOfSets, gamesPerSet, tiebrakeGamesEqual, pointsPerSet, pointsPerGame,
            pointsWoWinner, pointsWoLoser, pointsWoNoShowWinner, pointsWoNoShowLoser,
            pointsDrawSet, pointsSetLeader, pointsSetRunnerUp, leaderByNrOfGames
        } = this.state;
        return serverRules &&
            serverRules.nrOfSets === nrOfSets &&
            serverRules.gamesPerSet === gamesPerSet &&
            serverRules.tiebrakeGamesEqual === tiebrakeGamesEqual &&
            serverRules.pointsPerSet === pointsPerSet &&
            serverRules.pointsPerGame === pointsPerGame &&
            serverRules.pointsWoWinner === pointsWoWinner &&
            serverRules.pointsWoLoser === pointsWoLoser &&
            serverRules.pointsWoNoShowWinner === pointsWoNoShowWinner &&
            serverRules.pointsWoNoShowLoser === pointsWoNoShowLoser &&
            serverRules.pointsDrawSet === pointsDrawSet &&
            serverRules.pointsSetLeader === pointsSetLeader &&
            serverRules.pointsSetRunnerUp === pointsSetRunnerUp &&
            serverRules.leaderByNrOfGames === leaderByNrOfGames;
    };

    private nrOfSetsChanged = (value: number) => {
        this.setState({nrOfSets: value});
    };

    private gamesPerSetChanged = (value: number) => {
        this.setState({gamesPerSet: value});
    };

    private tiebrakeGamesEqualChanged = (value: number) => {
        this.setState({tiebrakeGamesEqual: value});
    };

    private pointsPerSetChanged = (value: number) => {
        this.setState({pointsPerSet: value});
    };
    private pointsPerGameChanged = (value: number) => {
        this.setState({pointsPerGame: value});
    };
    private pointsWoWinnerChanged = (value: number) => {
        this.setState({pointsWoWinner: value});
    };
    private pointsWoLoserChanged = (value: number) => {
        this.setState({pointsWoLoser: value});
    };
    private pointsWoNoShowWinnerChanged = (value: number) => {
        this.setState({pointsWoNoShowWinner: value});
    };
    private pointsWoNoShowLoserChanged = (value: number) => {
        this.setState({pointsWoNoShowLoser: value});
    };
    private pointsDrawSetChanged = (value: number) => {
        this.setState({pointsDrawSet: value});
    };
    private pointsSetLeaderChanged = (value: number) => {
        this.setState({pointsSetLeader: value});
    };
    private pointsSetRunnerUpChanged = (value: number) => {
        this.setState({pointsSetRunnerUp: value});
    };
    private leaderByNrOfGamesChanged = (value: number) => {
        this.setState({leaderByNrOfGames: value});
    };

}