import * as React from "react"
import {mobile} from "../App"

interface Props {
    onChange: (currentValue: number, valid: boolean) => void
    autoFocus?: boolean
    value?: number
}

interface State {
    valid: boolean
    value: string
}

export default class HourInput extends React.Component<Props, State> {

    HOUR_REGEXP = /^$|^(([01][0-9])|(2[0-3]))$/;

    constructor(props: Props) {
        super(props);
        const value : string = '' + (this.props.value ? this.props.value : '');
        this.state = {
            value: value,
            valid: this.validate(value)
        };
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const mobileStyle = {
            width: "5rem",
            border: this.state.valid ? "0.5rem solid var(--buttonBg)" : "0.5rem solid red"
        };
        const deskStyle = {
            width: "1.25rem",
            border: this.state.valid ? "0.3rem solid var(--buttonBg)" : "0.3rem solid red"
        };
        const style = mobile ? mobileStyle : deskStyle;
        return (
            <input type="text" placeholder={"tt"} onChange={this.onChange}
                   value={this.state.value}
                   style={style}
                   name={"tt"} autoFocus={this.props.autoFocus} />
        );
    }

    onChange(event: React.FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        const valid = this.validate(value);
        this.setState(
            {
                value: value,
                valid: valid
            });
        if(valid) {
            this.props.onChange(parseInt(value), valid);
        } else {
            this.props.onChange(0, valid);
        }
    }

    validate(value: string): boolean {
        return value !== '' && this.HOUR_REGEXP.test(value);
    }
}