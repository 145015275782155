import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "./BasePage";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    fatalError: boolean
}

class TestPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false
        }
    }

    async componentDidMount() {
        //TODO!
    }


    render() {
        const {groupplayId} = this.props.match.params
        return (

            <BasePage {...this.props} fatalError={this.state.fatalError}>
                Testing...... {groupplayId}

            </BasePage>
        );
    }

}

export default TestPage;