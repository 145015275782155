import * as React from "react"

interface Props {
    size?: string
    marginBottom?:string
}

export default class TennisBall extends React.Component<Props> {

    render() {
        const size = this.props.size ? this.props.size : "1em";
        const marginBottom = this.props.marginBottom ? this.props.marginBottom : "-0.15rem";
        return (
                <svg version="1.1" id="Layer_1" x="0px" y="0px" style={{marginBottom:marginBottom}} width={size} height={size}
                     viewBox="0 0 512 512">
                    <path style={{fill: "#B5CE00"}} d="M503.916,256c0,136.92-110.996,247.916-247.916,247.916S8.084,392.92,8.084,256
	S119.08,8.084,256,8.084S503.916,119.08,503.916,256z"/>
                    <path style={{fill: "#FFFFFF"}} d="M466.223,289.751c-7.882,49.459-33.236,94.79-71.394,127.642
	c-38.567,33.205-87.871,51.491-138.829,51.491c-4.465,0-8.084-3.62-8.084-8.084c0-4.465,3.62-8.084,8.084-8.084
	c97.278,0,178.975-69.606,194.255-165.509c0.703-4.41,4.848-7.413,9.256-6.712C463.92,281.198,466.926,285.342,466.223,289.751z
	 M460.8,247.916c-4.465,0-8.084,3.62-8.084,8.084l-0.005,1.425c-0.033,4.465,3.559,8.11,8.024,8.145c0.021,0,0.041,0,0.061,0
	c4.437,0,8.05-3.58,8.083-8.024c0.003-0.515,0.005-1.029,0.005-1.545C468.884,251.535,465.265,247.916,460.8,247.916z"/>
                    <path style={{fill: "#FFFFA9"}} d="M406.905,59.309V256c0,83.343-67.562,150.905-150.905,150.905l0,0
	c-83.343,0-150.905-67.562-150.905-150.905V59.309C146.894,27.192,199.212,8.084,256,8.084S365.106,27.192,406.905,59.309z"/>
                    <path style={{fill: "#CDEF00"}} d="M374.568,38.229V256c0,65.379-53.19,118.568-118.568,118.568S137.432,321.379,137.432,256V38.229
	C172.654,19.011,213.05,8.084,256,8.084S339.346,19.011,374.568,38.229z"/>
                    <path d="M437.019,74.981C388.667,26.628,324.379,0,256,0S123.333,26.628,74.981,74.981S0,187.62,0,256
	s26.628,132.667,74.981,181.019S187.62,512,256,512s132.667-26.628,181.019-74.981S512,324.379,512,256
	S485.372,123.333,437.019,74.981z M113.179,63.451c5.229-3.888,10.626-7.56,16.168-11.021V256
	c0,69.837,56.816,126.653,126.653,126.653S382.653,325.837,382.653,256V52.43c5.543,3.461,10.94,7.134,16.168,11.021V256
	c0,78.752-64.069,142.821-142.821,142.821S113.179,334.752,113.179,256V63.451z M366.484,43.182V256
	c0,60.922-49.563,110.484-110.484,110.484S145.516,316.922,145.516,256V43.182C178.601,25.935,216.179,16.168,256,16.168
	S333.399,25.935,366.484,43.182z M256,495.832C123.756,495.832,16.168,388.244,16.168,256c0-71.302,31.288-135.423,80.842-179.392
	V256c0,87.667,71.322,158.989,158.989,158.989S414.989,343.667,414.989,256V76.608c49.554,43.968,80.842,108.09,80.842,179.392
	C495.832,388.244,388.244,495.832,256,495.832z"/>
                </svg>
        );
    }
}