import * as React from "react"
import {CSSProperties} from "react"
import {mobile} from "../App"

interface Props {
    left?: string
    mobLeft?: string
    right?: string
    mobRight?: string
}

export default class TableCheckbox extends React.Component<Props> {
    render() {
        const top = mobile ? "1rem" : "0.25rem";
        const baseCss: CSSProperties = {position: "absolute", top: top};
        const leftCss: CSSProperties = mobile ?
            this.props.mobLeft ? {left: this.props.mobLeft} : {}
            :
            this.props.left ? {left: this.props.left} : {};
        const rightCss: CSSProperties = mobile ?
            this.props.mobRight ? {right: this.props.mobRight} : {}
            :
            this.props.right ? {right: this.props.right} : {};
        const css = {...baseCss, ...leftCss, ...rightCss};

        return (
            <div style={css}>
                {this.props.children}
            </div>
        );
    }
}