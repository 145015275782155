import * as React from "react"
import Checkbox from "../../../components/Checkbox"
import {
    BookingSystemSettingsDto,
    CourtNameAliasDto,
    MatchiConfigDto,
    NasmoConfigDto,
    TestBookingSystemDto
} from "../../../api/dtos"
import MatchiConfig from "./MatchiConfig"
import NasmoConfig from "./NasmoConfig"
import BasePage, {BasePageProps} from "../../BasePage"
import {
    getBookingsSystemSettings,
    getCourtNameAliases,
    saveBookingsSystemSettings, saveCourtNameAliases,
    testBookingSystem
} from "../../../api/api"
import {RouteComponentProps} from "react-router"
import FullPageSpinner from "../../../components/FullPageSpinner"
import FullPageMessage from "../../../components/FullPageMessage"
import {ErrorMessage} from "../../../components/ErrorMessage"
import Panel from "../../../components/Panel"
import TextInput from "../../../components/TextInput";
import {mobile} from "../../../App";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

enum BookingSystem {
    NASMO = "NASMO",
    MATCHI = "MATCHI"
}

interface State {
    bookingSystem: BookingSystem
    config?: BookingSystemSettingsDto
    matchi: MatchiConfigDto
    nasmo: NasmoConfigDto
    courtNameAliases: CourtNameAliasDto[]
    serverCourtNameAliases: CourtNameAliasDto[]
    loading: boolean
    fatalError: boolean
    testingCommunication: boolean,
    communicationSuccessful: boolean,
    futureBookingsFound: number,
    errorMessage?: string
}

export default class BookingsSystemSettingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            fatalError: false,
            bookingSystem: BookingSystem.MATCHI,
            matchi: {bookingUsername: "", adminUsername: "", adminPassword: ""},
            nasmo: {systemId: "", bookingUsername: "", adminUsername: "", adminPassword: ""},
            testingCommunication: false,
            communicationSuccessful: false,
            futureBookingsFound: 0,
            courtNameAliases: [],
            serverCourtNameAliases: []
        }
    }

    async componentDidMount() {
        const {groupplayId} = this.props.match.params
        if (this.props.session) {
            try {
                const config: BookingSystemSettingsDto = await getBookingsSystemSettings(groupplayId, this.props.session.sessionId)
                const aliases: CourtNameAliasDto[] = await getCourtNameAliases(groupplayId, this.props.session.sessionId)
                this.setState({
                    config: config,
                    nasmo: config.nasmo ? config.nasmo : {systemId: "", bookingUsername: "", adminUsername: "", adminPassword: ""},
                    matchi: config.matchi ? config.matchi : {bookingUsername: "", adminUsername: "", adminPassword: ""},
                    bookingSystem: config.matchi ? BookingSystem.MATCHI : BookingSystem.NASMO,
                    loading: false,
                    courtNameAliases: aliases,
                    serverCourtNameAliases: aliases
                })
            } catch (error: any) {
                console.log('ERROR:' + error.type)
                switch (error.type) {
                    case "NOT_FOUND":
                        this.setState({loading: false})
                        break
                    default:
                        this.setState({fatalError: true})
                        break
                }
            }
        }
    }

    render() {
        const {
            bookingSystem, matchi, nasmo, loading, fatalError, testingCommunication, communicationSuccessful,
            futureBookingsFound, errorMessage, courtNameAliases
        } = this.state
        if (testingCommunication && !fatalError) {
            return (<FullPageSpinner text="Testar kommunikationen med bokningssystemet.."/>)
        }
        if (communicationSuccessful) {
            return (<BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <FullPageMessage text={"Kommunikationen med bokningssystemet fungerar! Hittade " +
                    futureBookingsFound + " bokningar de kommande 30 dagarna."}/>
            </BasePage>)
        }
        return (
            <BasePage {...this.props} adminPage={true} loading={loading} fatalError={fatalError}>
                <Panel center={true}>
                    {errorMessage && <ErrorMessage text={errorMessage}/>}
                    <div style={{display: "flex", alignItems: "center", justifyContent:"center"}}>
                        <Checkbox checked={bookingSystem === BookingSystem.MATCHI} onChange={this.matchiCheckboxClicked}
                                  id={"matchi"}/>
                        <span style={{marginLeft: "0.5rem", marginRight: "2rem"}}>Matchi</span>
                        <Checkbox checked={bookingSystem === BookingSystem.NASMO} onChange={this.nasmoCheckboxClicked}
                                  id={"nasmo"}/>
                        <span style={{marginLeft: "0.5rem"}}>nasMo</span>
                    </div>
                    {bookingSystem === BookingSystem.MATCHI &&
                        <MatchiConfig matchi={matchi} onChange={this.matchiChanged}/>}
                    {bookingSystem === BookingSystem.NASMO && <NasmoConfig nasmo={nasmo} onChange={this.nasmoChanged}/>}
                    <button style={{padding: "0 1rem"}} onClick={this.handleSaveClick}>Spara</button>
                </Panel>
                <Panel heading="Namn-alias för banor">

                    <div style={{display: "flex", flexDirection: "column", marginTop: "2rem", alignItems: "center"}}>
                        {courtNameAliases
                            .sort((a1, a2) => {
                                if(a1.courtName === "") {
                                    return 1;
                                }
                                if(a2.courtName === "") {
                                    return -1;
                                }

                             return a1.courtName.localeCompare(a2.courtName)
                            })
                            .map((a, i) => this.getAlias(a))}
                        <div>
                            <button style={{padding: "0 1rem"}} onClick={this.addAlias}>Nytt alias</button>
                            <button style={{padding: "0 1rem"}} disabled={this.saveAliasesDisabled()} onClick={this.saveAlias}>Spara</button>
                        </div>
                    </div>
                </Panel>
            </BasePage>
        )
    }

    getAlias(a: CourtNameAliasDto) {
        return (
            <div key={a.id}>
                <TextInput width="20rem" value={a.courtName} onChange={(value) => this.handleAliasCourtNameChange(value, a.id)} placeHolder={"Banans namn i bokningssystemet"}/>
                {mobile ? "" : "="}
                <TextInput value={a.alias} onChange={(value) => this.handleAliasChange(value, a.id)} placeHolder={"Alias"}/>
                <button onClick={() => this.removeAlias(a.id)}>ta bort</button>
                {mobile ? <div style={{height:"5rem"}}/> : ""}
            </div>
        )
    }

    private handleAliasChange = (newAlias: string, id: number) => {
        this.setState(prevState => ({
            courtNameAliases: prevState.courtNameAliases.map(a => {
                    if(a.id === id) {
                        return {id: a.id, courtName: a.courtName, alias: newAlias}
                    } else {
                        return a;
                    }
                })
        }));
    };

    private handleAliasCourtNameChange = (newCourtName: string, id: number) => {
        this.setState(prevState => ({
            courtNameAliases: prevState.courtNameAliases.map(a => {
                if(a.id === id) {
                    return {id: a.id, courtName: newCourtName, alias: a.alias}
                } else {
                    return a;
                }
            })
        }));
    };

    private removeAlias = (id: number) => {
        console.log('removeAlias:' + id)
        this.setState(prevState =>
            ({courtNameAliases: prevState.courtNameAliases.filter(a => a.id !== id)}));

    }

    private handleSaveClick = async () => {
        this.setState({testingCommunication: true})
        const {groupplayId} = this.props.match.params
        const {bookingSystem, matchi, nasmo} = this.state
        if (this.props.session) {
            const request: BookingSystemSettingsDto = {
                nasmo: bookingSystem === BookingSystem.NASMO ? nasmo : undefined,
                matchi: bookingSystem === BookingSystem.MATCHI ? matchi : undefined
            }
            try {
                await saveBookingsSystemSettings(request, groupplayId, this.props.session.sessionId)
                const result: TestBookingSystemDto = await testBookingSystem(groupplayId, this.props.session.sessionId)
                this.setState({
                    testingCommunication: false,
                    communicationSuccessful: true,
                    futureBookingsFound: result.futureBookingsFound,
                    loading: false
                })
            } catch (error: any) {
                console.log('error type:' + error.type)
                switch (error.type) {
                    case "COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM":
                        this.setState({errorMessage: error.message, testingCommunication: false, loading: false})
                        break
                    default:
                        console.log("FATAL ERROR")
                        this.setState({fatalError: true})
                        break
                }
            }
        } else {
            this.setState({errorMessage: "", fatalError: true})
        }
    }

    private addAlias = () => {
        this.setState(prevState => ({
            courtNameAliases: [
                ...prevState.courtNameAliases,
                {id: Math.floor(Math.random() * 100000000), courtName: "", alias: ""}
            ]
        }));
    }

    private saveAlias = async () => {
        if (this.props.session) {
            this.setState({loading: true})
            const {groupplayId} = this.props.match.params
            await saveCourtNameAliases(this.state.courtNameAliases, groupplayId, this.props.session.sessionId)
            this.setState({loading: false})
        } else {
            this.setState({errorMessage: "", fatalError: true})
        }
    }

    private saveAliasesDisabled = (): boolean => {
        const {  courtNameAliases, serverCourtNameAliases} = this.state;
        for(const alias of courtNameAliases) {
            for(const alias2 of courtNameAliases) {
                if(alias.id === alias2.id) {
                    continue;
                }
                if(alias.courtName === alias2.courtName) {
                    return true;
                }
                if(alias.alias === alias2.alias) {
                    return true;
                }
            }
        }
        if(courtNameAliases.length !== serverCourtNameAliases.length) {
            return false;
        }
        for(const alias of courtNameAliases) {
           if(serverCourtNameAliases.find(a =>
               a.id === alias.id && a.courtName === alias.courtName && a.alias === alias.alias) === undefined) {
               return false;
           }
        }
        return true;
    }

    private matchiCheckboxClicked = () => {
        this.setState({bookingSystem: BookingSystem.MATCHI})
    }

    private nasmoCheckboxClicked = () => {
        this.setState({bookingSystem: BookingSystem.NASMO})
    }

    private matchiChanged = (matchi: MatchiConfigDto) => {
        this.setState({matchi: matchi})
    }

    private nasmoChanged = (nasmo: NasmoConfigDto) => {
        this.setState({nasmo: nasmo})
    }
}
