import * as React from "react"
import {PlayerDto, PlayerState} from "../../../api/dtos"
import Table from "../../../components/Table"
import TableRow from "../../../components/TableRow"
import TableText from "../../../components/TableText"
import TableButton, {ButtonType} from "../../../components/TableButton"
import DropDown from "../../../components/DropDown"
import PlayerDtoSorter from "./PlayerDtoSorter"
import VerticalLine from "../../../components/VerticalLine"
import {mobile} from "../../../App"
import EditPlayerModal from "./EditPlayerModal"
import StateToString from "../../../util/StateToString"
import ConfirmModal from "../../../components/ConfirmModal"

interface Props {
    players: PlayerDto[]
    onPlayerGroupsChanged: (players: PlayerDto[]) => void
    savePlayer: (player: PlayerDto) => void
    removePlayer: (player: PlayerDto) => void
}

interface State {
    groupDropdownPlayerId?: number
    editPlayer?: PlayerDto
    removePlayer?: PlayerDto
}

export default class PlayersTables extends React.Component<Props, State> {

    static readonly DOWN_COLOR: string = "#FFFF00";
    static readonly NEUTRAL_COLOR: string = "var(--panelText)"
    static readonly UP_COLOR: string = "#00AA00";
    static readonly NEW_COLOR: string = "#6666FF";

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const {removePlayer} = this.state;
        return (
            <>
                {this.renderNoGroup()}
                {this.renderGroups()}
                {removePlayer && <ConfirmModal
                    text={"Vill du ta bort " + removePlayer.account.firstName + " " + removePlayer.account.lastName +
                    " från gruppspelet (Borttagningen sparas direkt)?"}
                    onCancel={() => this.setState({removePlayer: undefined})}
                    onOk={() => this.removePlayer(removePlayer)}/>}
                {this.state.editPlayer &&
                <EditPlayerModal onClose={this.closeEditPlayerModal}
                                 savePlayer={this.props.savePlayer}
                                 player={this.state.editPlayer}/>}
                <div style={{marginBottom: mobile?"10rem":"5rem"}}/>
            </>
        );
    }

    private renderGroups() {
        const nrOfGroups = this.nrOfGroups();
        let groups : React.JSX.Element[] = [];
        for (let i = 1; i <= nrOfGroups; i++) {
            groups.push(this.renderGroup(i));
        }
        return groups;
    }

    private renderNoGroup() {
        const {players} = this.props;
        const filtered = players.filter(player => player.groupNumber === undefined);
        const nrOfPlayers = filtered.length;

        return (
            <Table heading="Ingen grupp">
                {filtered.map((player, i) =>
                    this.noGroupPlayer(player, nrOfPlayers === (i + 1)))}
            </Table>);
    }

    noGroupPlayer(player: PlayerDto, lastPlayer: boolean) {
        const nrOfGroups = this.nrOfGroups();
        const groupListItems: JSX.Element[] = [];
        for (let i = 1; i <= nrOfGroups; i++) {
            groupListItems.push(<li key={i} style={{padding: "0.25rem", cursor: "pointer"}}
                                    onClick={() => this.moveToGroup(player, i)}>grupp {i}</li>);
        }
        const typeClass: string =
            (player.state === PlayerState.PLAYING || player.state === PlayerState.WANNA_JOIN) ?
                "pulsate-red" : "";

        return (
            <TableRow key={"no-group-" + player.account.id} fourMobRows lastRow={lastPlayer}>
                <TableButton type={ButtonType.DOWN_ARROW} left="-0.25rem" mobLeft="-1.25rem"
                             onClick={() => this.handleAddToGroupClick(player)}/>
                {player.account.id === this.state.groupDropdownPlayerId &&
                <DropDown>
                    {groupListItems}
                </DropDown>}
                <TableText left="3rem" mobLeft="5.5rem"
                           mobFirstRow>{player.account.firstName + ' ' + player.account.lastName}</TableText>
                <TableText left="27.5rem" mobLeft="5.5rem" mobSecondRow>
                    <span className={typeClass}>{StateToString.stateToString(player.state)}</span>
                </TableText>
                <TableText left="34.5rem" mobLeft="6rem" mobThirdRow>
                    <div style={{
                        fontSize: mobile ? "85%" : "70%",
                        maxWidth: mobile ? "34rem" : "14rem",
                        marginTop: "-0.25rem", fontStyle: "italic"
                    }}>{player.comment}</div>
                </TableText>
                <TableButton right="18.5rem" mobRight="-1.25rem" mobDowner type={ButtonType.HAMBURGER}
                             onClick={() => this.setState({editPlayer: player})}/>
                <TableButton type={ButtonType.REMOVE} right="0" mobRight="-1.25rem"
                             onClick={() => this.setState({removePlayer: player})}/>
                {!mobile && <>
                    <VerticalLine left="26.5rem"/>
                    <VerticalLine right="20.5rem"/>
                    <VerticalLine right="5rem"/>
                </>}
            </TableRow>);
    }

    private renderGroup(groupNr: number) {
        const {players} = this.props;
        const filtered = players.filter(player => player.groupNumber === groupNr);
        const nrOfPlayers = filtered.length;

        return (
            <Table key={"group-" + groupNr} heading={"Grupp " + groupNr + " (" + nrOfPlayers + "st)"}>
                {filtered
                    .sort(PlayerDtoSorter.compare)
                    .map((player, i) => this.player(player, nrOfPlayers === (i + 1), groupNr))}
            </Table>);
    }

    player(player: PlayerDto, lastPlayer: boolean, groupNr: number) {

        const playerColor: string = player.ongoingRoundInfo ?
            (player.ongoingRoundInfo.groupNumber > groupNr ?
                PlayersTables.UP_COLOR :
                (player.ongoingRoundInfo.groupNumber < groupNr ?
                    PlayersTables.DOWN_COLOR : PlayersTables.NEUTRAL_COLOR)) : PlayersTables.NEW_COLOR;

        const typeClass: string =
            (player.state === PlayerState.WANNA_QUIT || player.state === PlayerState.WANNA_PAUSE ||
                player.state === PlayerState.PAUSE || player.state === PlayerState.NO_ANSWER) ?
                "pulsate-red" : "";

        const playedMatchesClass: string = player.ongoingRoundInfo &&
        player.ongoingRoundInfo.nrOfMatchesPlayed < player.ongoingRoundInfo.nrOfMatches ? "pulsate-red" : "";

        return (
            <TableRow key={"player-" + player.account.id} fourMobRows lastRow={lastPlayer}>
                <TableButton type={ButtonType.UP_ARROW} left="-0.25rem" mobLeft="-1.25rem"
                             onClick={() => this.handleArrowClick(player, true)}
                             disabled={player.groupNumber === 1}/>
                <TableButton type={ButtonType.DOWN_ARROW} left="1.5rem" mobLeft="-1.25rem" mobDowner
                             onClick={() => this.handleArrowClick(player, false)}/>
                <TableText left="4rem" mobLeft="6rem" color={playerColor}
                           mobFirstRow>{player.account.firstName + ' ' + player.account.lastName}</TableText>

                {player.ongoingRoundInfo &&
                <>
                    <TableText right="36rem" mobRight="38rem" mobSecondRow>
                        {player.ongoingRoundInfo.groupNumber}:{player.ongoingRoundInfo.position}</TableText>

                    <TableText right="33rem" mobRight="31rem" mobSecondRow>
                        {player.ongoingRoundInfo.points/10}p</TableText>

                    <TableText right="31rem" mobRight="26rem" mobSecondRow>
                        {player.ongoingRoundInfo.gameDiff > 0 ? "+" + player.ongoingRoundInfo.gameDiff : player.ongoingRoundInfo.gameDiff}</TableText>

                    <TableText right="28.5rem" mobRight="18rem" mobSecondRow>
                        <span
                            className={playedMatchesClass}>{player.ongoingRoundInfo.nrOfMatchesPlayed}/{player.ongoingRoundInfo.nrOfMatches}</span>
                    </TableText>
                </>}
                {!mobile && <>
                    <VerticalLine left="15rem"/>
                    <VerticalLine right="35.6rem"/>
                    <VerticalLine right="32.8rem"/>
                    <VerticalLine right="30.5rem"/>
                    <VerticalLine right="27.8rem"/>
                    <VerticalLine right="22.9rem"/>
                    <VerticalLine right="20.5rem"/>
                    <VerticalLine right="5rem"/>
                </>}
                <TableText left="25.5rem" mobLeft="27rem" mobFirstRow>
                    <span className={typeClass}>{StateToString.stateToString(player.state)}</span>
                </TableText>
                <TableText right="20.7rem" mobLeft="38rem" mobFirstRow>
                    {player.account.lastActivity}
                </TableText>
                <TableButton right="18.5rem" mobRight="-1.25rem" mobDowner type={ButtonType.HAMBURGER}
                             onClick={() => this.setState({editPlayer: player})}/>
                <TableText left="34.5rem" mobLeft="6rem" mobThirdRow>
                    <div style={{
                        fontSize: mobile ? "85%" : "70%",
                        maxWidth: mobile ? "34rem" : "14rem",
                        marginTop: "-0.25rem", fontStyle: "italic",whiteSpace:"pre-wrap"
                    }}>{player.comment}</div>
                </TableText>
                <TableButton type={ButtonType.REMOVE} right="0" mobRight="-1.25rem"
                             onClick={() => this.moveToGroup(player, undefined)}/>
            </TableRow>
        );
    }

    private closeEditPlayerModal = () => {
        this.setState({editPlayer: undefined});
    }

    private moveToGroup(player: PlayerDto, groupNumber?: number) {
        const players = this.props.players.slice();
        for (let idx = 0; idx < players.length; idx++) {
            if (players[idx].account.id === player.account.id) {
                players[idx] = {...players[idx], groupNumber: groupNumber}
            }
        }
        this.props.onPlayerGroupsChanged(players);
    }

    private handleAddToGroupClick(player: PlayerDto) {
        if (this.state.groupDropdownPlayerId === player.account.id) {
            this.setState({groupDropdownPlayerId: undefined});
        } else {
            this.setState({groupDropdownPlayerId: player.account.id});
        }
    }

    private handleArrowClick(player: PlayerDto, up: boolean) {
        if (player.groupNumber) {
            this.moveToGroup(player, player.groupNumber + (up ? -1 : 1));
        }
    }

    private nrOfGroups() {
        const {players} = this.props;
        let highestGroupNr = 0;
        players.forEach(player => {
            if (player.groupNumber && player.groupNumber > highestGroupNr) {
                highestGroupNr = player.groupNumber;
            }
        });
        return highestGroupNr;
    }

    private removePlayer = (player: PlayerDto) => {
        this.props.removePlayer(player);
        this.setState({removePlayer: undefined});
    }
}
