import * as React from "react"
import {mobile} from "../App"

interface Props {
    lastRow?: boolean
    width?: string
    mobWidth?: string
    fourMobRows?: boolean
    height?: string
}

export default class TableRow extends React.Component<Props> {
    render() {
        const height = this.props.height ? this.props.height :
            (mobile ? (this.props.fourMobRows ? "11rem" : "6rem") : "2rem");
        const fontSize = mobile ? "2rem" : "1rem";
        const width = mobile ? (this.props.mobWidth ? this.props.mobWidth : "47rem") :
            (this.props.width ? this.props.width : "53rem");

        return (
            <div style={{
                position: "relative",
                width: width,
                height: height,
                fontSize: fontSize,
                margin: "0 0.5rem 0 0.5rem",
                borderBottom: this.props.lastRow ? "" : "2px solid var(--panelTableLine)"
            }}>
                {this.props.children}
            </div>
        );
    }
}