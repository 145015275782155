import {WeekDay} from "../api/dtos";

export default class DateUtil {

    static format(hour: number, minute: number) {
        if(minute < 10) {
            return hour + "0" + minute;
        }
        return hour + "" + minute;
    }

    static shortDayName(day: WeekDay): string {
        switch (day) {
            case WeekDay.MONDAY:
                return "må";
            case WeekDay.TUESDAY:
                return "ti";
            case WeekDay.WEDNESDAY:
                return "on";
            case WeekDay.THURSDAY:
                return "to";
            case WeekDay.FRIDAY:
                return "fr";
            case WeekDay.SATURDAY:
                return "lö";
            case WeekDay.SUNDAY:
                return "sö";
        }
    }

    static dateToDayName(d: string) {
        const date = new Date(d);
        switch (date.getDay()) {
            case 0:
                return "söndag";
            case 1:
                return "måndag";
            case 2:
                return "tisdag";
            case 3:
                return "onsdag";
            case 4:
                return "torsdag";
            case 5:
                return "fredag";
            case 6:
                return "lördag";
        }
        return "Error";
    }

    static dayName(day: WeekDay): string {
        switch (day) {
            case WeekDay.MONDAY:
                return "måndag";
            case WeekDay.TUESDAY:
                return "tisdag";
            case WeekDay.WEDNESDAY:
                return "onsdag";
            case WeekDay.THURSDAY:
                return "torsdag";
            case WeekDay.FRIDAY:
                return "fredag";
            case WeekDay.SATURDAY:
                return "lördag";
            case WeekDay.SUNDAY:
                return "söndag";
        }
    }

    static monthName(month: number): string {
        switch (month) {
            case 0:
                return "Januari";
            case 1:
                return "Februari";
            case 2:
                return "Mars";
            case 3:
                return "April";
            case 4:
                return "Maj";
            case 5:
                return "Juni";
            case 6:
                return "Juli";
            case 7:
                return "Augusti";
            case 8:
                return "September";
            case 9:
                return "Oktober";
            case 10:
                return "November";
            case 11:
                return "December";
        }
        return "Weird month!"
    }

    static shortDate(d: string) {
        const date = new Date(d);
        return date.getDate() + "/" + (date.getMonth()+1);
    }



    static daysBetween(d1: Date, d2: Date): number {
        var diff = Math.abs(d1.getTime() - d2.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        return diffDays;
    }

    static localDateString(d: Date): string {
        return d.toISOString().slice(0, 10);
    }
}