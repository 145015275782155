import * as React from "react"
import {mobile} from "../App"

interface Props {
    text?: string
    desktopWidth?: string
}

export class ErrorMessage extends React.Component<Props> {

    render() {
        const fontSize = mobile ? "3rem" : "1rem";
        const textMargin = mobile ? "1.5rem" : "0.5rem";
        const width = mobile ? "80vw" : (this.props.desktopWidth ? this.props.desktopWidth : "11rem");
        if (this.props.text) {
            return (
                <div style={{
                    fontSize: fontSize,
                    color: "red",
                    margin: textMargin,
                    width: width
                }}>{this.props.text}</div>
            );
        }
        return null;
    }
}
