import * as React from "react"
import {KeyboardEvent} from "react"
import {mobile} from "../App"

interface Props {
    onChange: (currentValue: string, valid: boolean) => void
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
    autoFocus?: boolean
    placeHolder: string
    allowEmpty?: boolean
    value?: string
}

interface State {
    valid: boolean
    value: string
}

export default class PhoneInput extends React.Component<Props, State> {

    PHONE_REGEXP = /^[0-9-]*$/;

    constructor(props: Props) {
        super(props);
        const phoneValue = this.props.value ? this.props.value : '';
        this.state = {
            value: phoneValue,
            valid: this.validate(phoneValue)
        };
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const mobileStyle = {
            width: "80vw",
            border: this.state.valid ? "0.5rem solid var(--buttonBg)" : "0.5rem solid red"
        };
        const deskStyle = {
            width: "10rem",
            border: this.state.valid ? "0.3rem solid var(--buttonBg)" : "0.3rem solid red"
        };
        const style = mobile ? mobileStyle : deskStyle;
        return (
            <input type="text" placeholder={this.props.placeHolder} onChange={this.onChange}
                   value={this.state.value}
                   style={style}
                   name={this.props.placeHolder} autoFocus={this.props.autoFocus} onKeyDown={this.props.onKeyDown}/>
        );
    }

    onChange(event: React.FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        const valid = this.validate(value);
        this.setState(
            {
                value: value,
                valid: valid
            });
        this.props.onChange(value, valid);
    }

    validate(value: string): boolean {
        return this.PHONE_REGEXP.test(value) && (this.props.allowEmpty || value.length !== 0);
    }
}