import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getRuleText} from "../../api/api";
import {RuleParagraphDto} from "../../api/dtos";
import Panel from "../../components/Panel";
import {mobile} from "../../App";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    loading: boolean
    fatalError: boolean
    paragraphs: RuleParagraphDto[]
}

export default class RulesPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
            paragraphs: []
        }
    }

    async componentDidMount() {
        try {
            const {groupplayId} = this.props.match.params;
            const paragraphDtos: RuleParagraphDto[] = await getRuleText(groupplayId);
            this.setState({paragraphs: paragraphDtos, loading: false});
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                default:
                    this.setState({fatalError: true, loading: false});
                    break;
            }
        }
    }

    render() {
        const {loading, fatalError, paragraphs} = this.state;
        return (
            <BasePage {...this.props} loading={loading} fatalError={fatalError}>
                <span style={{textAlign:"center", fontSize:mobile?"4rem":"2rem"}}>Regler</span>
                {paragraphs.map((p, i) =>
                    <Panel key={p.number} preWrapWhiteSpace={true} heading={"§" + p.number + " " + p.heading}>
                            {p.text}
                    </Panel>)}
            </BasePage>
        );
    }
};