import * as React from "react"
import {CSSProperties} from "react"

interface Props {
    onClick: () => void
    style?: CSSProperties
    text?: string
}

export class LinkButton extends React.Component<Props> {

    render() {
        const style: CSSProperties = {
            cursor: "pointer",
            background: "none",
            border: "none",
            color: "var(--buttonBg)",
            padding: 0,
            margin: 0,
        }

        return (
            <button style={{...style, ...this.props.style}}
                    onClick={() => this.props.onClick()}> {this.props.text ? this.props.text : "Tillbaka"} </button>
        )
    }
}
