import * as React from "react"
import {RouteComponentProps} from "react-router";
import BasePage, {BasePageProps} from "../BasePage";
import {getPlayerState, getStandings} from "../../api/api";
import {PlayerState, PlayerStateDto, RoundStandingsDto} from "../../api/dtos";
import FullPageMessage from "../../components/FullPageMessage";
import Standings from "./Standings";
import {Link} from "react-router-dom";
import {mobile} from "../../App";

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

interface State {
    loading: boolean
    fatalError: boolean
    standings?: RoundStandingsDto
    playerState?: PlayerState
    activeSignupMail: boolean
}

export default class StandingsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            fatalError: false,
            activeSignupMail: false
        }
    }

    async componentDidMount() {
        this.setState({loading: true});
        const {groupplayId} = this.props.match.params;
        try {
            const session = this.props.session;
            const sessionId = session ? session.sessionId : undefined;
            const standingsDto = await getStandings(groupplayId, sessionId);
            this.setState({standings: standingsDto});
        } catch (error:any) {
            switch (error.type) {
                case "NOT_FOUND":
                    //just ignore..
                    break;
                default:
                    console.log('ERROR' + error);
                    this.setState({fatalError: true});
                    break;
            }
        }
        if (this.props.session) {
            try {
                const playerState: PlayerStateDto = await getPlayerState(groupplayId, this.props.session.sessionId);
                this.setState({
                    playerState: playerState.state,
                    activeSignupMail: playerState.activeSignupMail
                });
            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
        this.setState({loading: false})
    }

    render() {
        const {groupplayId} = this.props.match.params;

        return (
            <BasePage {...this.props} loading={this.state.loading} fatalError={this.state.fatalError}
                      hideStandingsButton={true}>
                {!this.state.standings &&
                <FullPageMessage text={"Det finns inget spelschema än.."}/>}

                {this.playerStateLink(groupplayId)}

                {this.state.standings &&
                <Standings standings={this.state.standings} groupplayId={groupplayId}/>}
            </BasePage>
        );
    }

    private playerStateLink(groupplayId: string) {
        const { playerState, activeSignupMail} = this.state;
        if(this.props.session && this.props.session.adminGroupplayIds.includes(groupplayId)) {
            //admin, lets skip showing the link
            return
        }
        const linkText = this.props.session ? this.stateToLinkText(playerState) : "Vill du vara med? Klicka här!";
        const fontSize = mobile ? "2rem" : "1rem";
        if (playerState && playerState === PlayerState.PLAYING && !activeSignupMail) {
            return;
        }
        return (<>
            <div style={{margin: "auto", fontSize: fontSize}}>
                {playerState === PlayerState.NO_ANSWER && <span className="pulsate-red"> OBS! </span>}
                <Link to={"/gruppspel/" + groupplayId + "/signup"}>{linkText}</Link>
                {playerState === PlayerState.NO_ANSWER && <span className="pulsate-red"> OBS! </span>}
            </div>
        </>);
    }

    private stateToLinkText(state?: PlayerState): string {
        switch (state) {
            case PlayerState.DONT_WANNA_JOIN:
                return "Du har anmält att INTE vara med nästa omgång!";
            case PlayerState.WANNA_JOIN:
                return "Du har anmält att börja nästa omgång!";
            case PlayerState.WANNA_PAUSE:
                return "Du har anmält att du vill pausa gruppspelet!";
            case PlayerState.WANNA_QUIT:
                return "Du har anmält att du vill hoppa av gruppspelet helt!";
            case PlayerState.PAUSE:
                return "Du har anmält att du tar en paus från gruppspelet..";
            case PlayerState.PLAYING:
                return "Du har anmält att du vill spela nästa omgång!";
            case PlayerState.NONE:
                return "Vill du vara med? Klicka här!";
            default:
                return "Du har inte svarat om du vill vara med nästa omgång!";
        }
    }

}
