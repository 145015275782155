import * as React from "react"
import {RouteComponentProps} from "react-router"
import BasePage, {BasePageProps} from "../../BasePage"
import {
    BookingsSummaryDto,
    GenerateScheduleState,
    GenerateScheduleStatusDto,
    NextRoundScheduleDto,
    OngoingDto,
    PlayerDto
} from "../../../api/dtos"
import {generateNewSchedule, getGenerateScheduleStatus, getNextRoundBookingsSummary, newRound} from "../../../api/api"
import ProgressBar from "../../../components/ProgressBar"
import NextRoundSchedule from "./NextRoundSchedule"
import FullPageMessage from "../../../components/FullPageMessage"
import {mobile} from "../../../App"
import Modal from "../../../components/Modal"
import Table from "../../../components/Table"
import TableRow from "../../../components/TableRow"
import TableText from "../../../components/TableText"
import StateToString from "../../../util/StateToString"
import BookingsSummaryTable from "../bookings/BookingsSummaryTable"

interface State {
    fatalError: boolean
    loading: boolean
    state: GenerateScheduleState
    ongoing?: OngoingDto
    schedule?: NextRoundScheduleDto
    maybeMissed: PlayerDto[]
    bookingsSummary?: BookingsSummaryDto
    timerToken?: NodeJS.Timeout
    newRoundCreated: boolean
    errorMessage?: string
    help: boolean
}

type Props = BasePageProps & RouteComponentProps<{ groupplayId: string }>

export default class AdminNewSchedulePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            fatalError: false,
            loading: true,
            state: GenerateScheduleState.NOT_ONGOING,
            maybeMissed: [],
            newRoundCreated: false,
            help: false
        };
    }

    async componentDidMount() {
        await this.updateStatus();
        if (!this.state.ongoing && !this.state.schedule) {
            await this.bookingsSummary();
        }
        this.setState({loading: false});
    }

    componentWillUnmount(): void {
        if (this.state.timerToken) {
            console.log("timeToken found, clearing!");
            clearInterval(this.state.timerToken);
        }
    }

    render() {
        if (this.state.errorMessage) {
            return (<BasePage {...this.props} adminPage={true} loading={this.state.loading}
                              fatalError={this.state.fatalError}>
                <FullPageMessage text={this.state.errorMessage}/>
            </BasePage>);
        }
        if (this.state.newRoundCreated) {
            return (<BasePage {...this.props} adminPage={true} loading={this.state.loading}
                              fatalError={this.state.fatalError}>
                <FullPageMessage text="Ny omgång skapad"/>
            </BasePage>)
        }
        const buttonWidth = mobile ? "35rem" : "12rem";
        const {bookingsSummary} = this.state;
        return (
            <BasePage {...this.props} adminPage={true} loading={this.state.loading} fatalError={this.state.fatalError}>
                {this.state.maybeMissed.length > 0 && <>
                    <div style={{margin:"2rem auto 0 auto", fontSize: mobile ? "2.5rem" : "1rem"}}>Dubbelkolla så att du inte har missat:</div>
                <Table>
                    {this.state.maybeMissed.map((player,i) =>
                        <TableRow width="25rem" key={player.account.id}
                                  lastRow={this.state.maybeMissed.length === (i + 1)}>
                            <TableText>{player.account.firstName} {player.account.lastName}
                                {player.groupNumber ? " i grupp " + player.groupNumber : " ej i någon grupp. Status: "}
                                <span className="pulsate-red"> {StateToString.stateToString(player.state)} </span></TableText>
                        </TableRow>)}
                </Table>
                    <div style={{height: "1rem"}} />
                </>}

                {bookingsSummary && !this.state.schedule && !this.state.ongoing &&
                <BookingsSummaryTable summary={bookingsSummary} showExtraTimes={true}/>}

                <div style={{margin: "auto", marginTop: "1rem", display: "flex", flexDirection: "column"}}>
                    {(this.state.schedule && !this.state.ongoing) && <div style={{display: "flex",justifyContent:"center"}}>
                        <button style={{width: buttonWidth, alignSelf: "center"}}
                                onClick={this.handleCreateNewRoundClicked}>Skapa ny omgång
                        </button>
                        <button style={{width: mobile ? "6rem" : "2rem"}}
                                onClick={() => this.setState({help: true})}> ?
                        </button>
                    </div>}
                    {(!this.state.schedule && !this.state.ongoing && this.state.bookingsSummary &&
                        (this.state.bookingsSummary.nrOfAvailableBookings >= this.state.bookingsSummary.nrOfMatches)) &&
                    <button style={{width: buttonWidth, alignSelf: "center", height: mobile ? "11rem" : "1.8rem"}}
                            onClick={this.handleGenerateClicked}> Generera spelschema</button>}
                    {this.state.ongoing && <div style={{margin: "1rem auto"}}>
                        <ProgressBar percentage={this.state.ongoing.percentage}/>
                    </div>}
                    {this.state.schedule &&
                    <NextRoundSchedule schedule={this.state.schedule}/>}
                    {this.state.help &&
                    <Modal height={mobile ? "45rem" : "15rem"} onClose={() => this.setState({help: false})}>
                        <span style={{fontSize: mobile ? "2.5rem" : "1rem"}}>
                        Siffrorna efter namnen anger hur "bra" matchen är på denna tid med hänsyn till
                        spelarens tidsönskemål och övriga matcher. Ju lägre siffra desto bättre. <br/> <br/>
                        Siffrorna är i tur och ordning: <br/>
                        <ul style={{paddingLeft: "2rem"}}>
                            <li> Matchfrekvens för spelare 1 </li>
                            <li> Tidsönskemål för spelare 1 </li>
                            <li> Tidsönskemål för spelare 2 </li>
                            <li> Matchfrekvens för spelare 2 </li>
                        </ul>
                            </span>
                    </Modal>}
                </div>
            </BasePage>
        );
    }

    private handleCreateNewRoundClicked = async () => {
        if (this.props.session) {
            const {groupplayId} = this.props.match.params;
            try {
                await newRound(groupplayId, this.props.session.sessionId);
                this.setState({newRoundCreated: true});
            } catch (error:any) {
                console.log('ERROR' + error);
                switch (error.type) {
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    private handleGenerateClicked = async () => {
        if (this.props.session) {
            const {groupplayId} = this.props.match.params;
            this.setState({loading: true});
            try {
                await generateNewSchedule(groupplayId, this.props.session.sessionId);
                await this.updateStatus();
                this.setState({loading: false});
            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    case "COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM":
                        this.setState({errorMessage: "Problem att kontakta bokningssystemet: " + error.message, loading: false})
                        break;
                    case "BOOKING_SYSTEM_CONFIG_NOT_FOUND":
                        this.setState({errorMessage: "Det finns ingen konfiguration för bokningssystem!", loading: false});
                        break;
                    case "NOT_ENOUGH_BOOKINGS":
                        this.setState({errorMessage: "Det finns inte tillräckligt med bokningar!", loading: false});
                        break;
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    private bookingsSummary = async () => {
        if (this.props.session) {
            const {groupplayId} = this.props.match.params;
            try {
                const response: BookingsSummaryDto = await getNextRoundBookingsSummary(groupplayId, this.props.session.sessionId);
                this.setState({
                    bookingsSummary: response,
                });
            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    case "COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM":
                        this.setState({errorMessage: "Kommunikationsproblem med bokningssystemet!"});
                        break;
                    case "BOOKING_SYSTEM_CONFIG_NOT_FOUND":
                        this.setState({errorMessage: "Det finns ingen konfiguration för bokningssystem!"});
                        break;
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }

    private updateStatus = async () => {
        if (this.props.session) {
            const {groupplayId} = this.props.match.params;
            try {
                const response: GenerateScheduleStatusDto = await getGenerateScheduleStatus(groupplayId, this.props.session.sessionId);
                this.setState({
                        state: response.state,
                        ongoing: response.ongoing,
                        schedule: response.schedule,
                        maybeMissed: response.maybeMissed
                    }
                );
                if (response.state === GenerateScheduleState.ONGOING ||
                    response.state === GenerateScheduleState.QUEUED) {
                    let token = setTimeout(this.updateStatus, 1000);
                    this.setState({timerToken: token});
                }
                if (response.state === GenerateScheduleState.NO_GROUPS) {
                    this.setState({errorMessage: "Det finns inga grupper!"});
                }
                if (response.state === GenerateScheduleState.NOT_ENOUGH_BOOKINGS) {
                    this.setState({errorMessage: "Det finns inte tillräckligt många bokingar i bokninssystemet!"});
                }
            } catch (error:any) {
                console.log('ERROR:' + error.type);
                switch (error.type) {
                    case "COMMUNICATION_PROBLEM_WITH_BOOKINGSYSTEM":
                        this.setState({errorMessage: "Kommunikationsproblem med bokningssystemet!"});
                        break;
                    case "BOOKING_SYSTEM_CONFIG_NOT_FOUND":
                        this.setState({errorMessage: "Det finns ingen konfiguration för bokningssystem!"});
                        break;
                    default:
                        this.setState({fatalError: true});
                        break;
                }
            }
        }
    }
}