import * as React from "react"

interface Props {
    percentage: number
}
export default class ProgressBar extends React.Component<Props> {
    render() {
        // Size of the enclosing square
        const sqSize = 200;
        const strokeWidth = 5;
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (sqSize - strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - dashArray * this.props.percentage / 100;

        return (
            <svg
                width={sqSize}
                height={sqSize}
                viewBox={viewBox}>
                <circle
                    style={{stroke: "var(--panelBg)", fill: "none"}}
                    cx={sqSize / 2}
                    cy={sqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}/>
                <circle
                    style={{
                        fill: "none",
                        stroke: "var(--pageText)",
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }}
                    cx={sqSize / 2}
                    cy={sqSize / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}/>
                <text
                    style={{
                        fontSize: "3rem",
                        fontWeight: "bold",
                        fill: "var(--pageText)"
                    }}
                    x="50%"
                    y="50%"
                    dy=".3em"
                    textAnchor="middle">
                    {`${this.props.percentage}%`}
                </text>
            </svg>
        );
    }
}