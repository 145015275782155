import * as React from "react"
import {mobile} from "../App"

interface Props {
    text: string
    button?: string
    onButtonClick?: () => void
}

export default class FullPageMessage extends React.Component<Props> {

    render() {
        const fontSize = mobile ? "4rem" : "2rem";
        return (
            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
                <div style={{margin: "auto",  padding: "1rem", display: "flex", flexDirection: "column"}}>
                    <div style={{margin: "auto", fontSize: fontSize, textAlign: "center"}}>{this.props.text}</div>
                    {this.props.button &&
                    <div style={{margin: "0.5rem", textAlign: "right"}}>
                        <button style={{width: "min-content", margin: "0.5rem"}} onClick={this.props.onButtonClick}>{this.props.button}</button>
                    </div>}
                </div>
            </div>
        );
    }
}