import * as React from "react"
import {Redirect, RouteComponentProps} from "react-router-dom";
import {verifyMail} from "../../api/api";
import {ErrorType, SessionDto} from "../../api/dtos"
import FullPageSpinner from "../../components/FullPageSpinner";
import FullPageMessage from "../../components/FullPageMessage";

interface VerifyMailPageProps {
    onSessionChange: (rememberMe: boolean, session: SessionDto) => void
}

type Props = VerifyMailPageProps & RouteComponentProps<{ linkId: string }>

interface State {
    redirectTo?: string
    rememberMe: boolean
    errorMessage?: string
    loading: boolean
}

class VerifyMailPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            rememberMe: false
        };
        this.okClick = this.okClick.bind(this);
    }

    async componentDidMount() {
        const {linkId} = this.props.match.params;
        try {
            let sessionDto = await verifyMail(linkId);
            this.props.onSessionChange(this.state.rememberMe, sessionDto);
        } catch (error:any) {
            console.log('ERROR' + error);
            switch (error.type) {
                case ErrorType.EMAIL_VERIFICATION_FAILED:
                case ErrorType.INVALID_LINK_ID:
                    this.setState({errorMessage: "E-mail verifieringen misslyckades."});
                    break;
                case ErrorType.EMAIL_ALREADY_VERIFIED:
                    this.setState({errorMessage: "E-mail adressen är redan verifierad!"});
                    break;
                default:
                    this.setState({errorMessage: error.type});
                    break;
            }

        }
        this.setState({loading: false});
    }

    render() {
        if (this.state.redirectTo) {
            return (<Redirect to={this.state.redirectTo}/>);
        }
        if(this.state.loading) {
            return (<FullPageSpinner/>);
        }
        if(this.state.errorMessage) {
            return (<FullPageMessage text={this.state.errorMessage}/>);
        }

        return (<FullPageMessage onButtonClick={this.okClick} button="Okej"
                                 text="Din E-mail adress är verifierad och ditt konto är klart att avändas!"/>);
    }

    okClick() {
        console.log("okClick");
        this.setState({redirectTo: '/'});
    }


}

export default VerifyMailPage;