import * as React from "react"
import {mobile} from "../App"

interface Props {
    onChange: (value: number) => void
    value: number
    min: number
    max: number
    disabled?: boolean
    step?: number
}

export default class NumberInput extends React.Component<Props> {

    render() {
        const {min, max, value, disabled} = this.props;
        const invalid: boolean = value > max || value < min;
        const width = mobile ? "6rem" : "1.5rem";
        const border = mobile ? "0.5rem solid var(--buttonBg)" : "0.3rem solid var(--buttonBg)"
        const borderInvalid = mobile ? "0.5rem solid red" : "0.3rem solid red";
        const borderDisabled = mobile ? "0.5rem solid var(--buttonDisabled)" : "0.3rem solid var(--buttonDisabled)"
        return (
            <div style={{display: "inline-block"}}>
                <button style={{width: width}} disabled={this.minusDisabled()} onClick={this.minusClicked}> -</button>
                <input type="text"
                       style={{
                           width: width,
                           textAlign: "center",
                           paddingLeft: "0.1rem",
                           paddingRight: "0.1rem",
                           margin: "0.5rem 0",
                           border: (invalid) ? borderInvalid : (disabled ? borderDisabled : border),
                           color: disabled ? "#333333" : "#000000",
                           backgroundColor: disabled ? "var(--buttonDisabled)" : "#FFFFFF"
                       }}
                       disabled={this.props.disabled}
                       onChange={this.onChange}
                       value={value}/>
                <button style={{width: width}} disabled={this.plusDisabled()} onClick={this.plusClicked}> +</button>
            </div>

        );
    }

    onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const value: string = event.currentTarget.value;
        const intValue = Number.parseInt(value);
        if (!Number.isNaN(intValue)) {
            this.props.onChange(intValue);
        }
    };

    plusClicked = () => {
        const value = this.props.value + (this.props.step ? this.props.step : 1);
        this.props.onChange(Math.round(10*value)/10);
    };

    minusClicked = () => {
        const value = this.props.value - (this.props.step ? this.props.step : 1);
        this.props.onChange(Math.round(10*value)/10);
    };

    plusDisabled = () => {
        return this.props.value >= this.props.max || this.props.disabled;
    };

    minusDisabled = () => {
        return this.props.value <= this.props.min || this.props.disabled;
    };
}